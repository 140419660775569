import React from 'react';
import styles from './segments.module.scss';

const SegmentsGroup = ({ children }) => {
  return (
    <div className={styles.segmentsGroup}>
      <div className={styles.segmentsGroupHandle}></div>
      <div className={styles.segmentsGroupContent}>{children}</div>
    </div>
  );
};

export default SegmentsGroup;
