import { constants } from '..';

const setCampaignsLoadingAction = flag => ({
  type: constants.campaigns.SET_CAMPAIGNS_LOADING,
  flag,
});

const getCampaignsAction = body => ({
  type: constants.campaigns.GET_CAMPAIGNS,
  body,
});

const setCampaignsAction = (campaigns, count, header) => ({
  type: constants.campaigns.SET_CAMPAIGNS,
  campaigns,
  count,
  header,
});

const clearCampaignsAction = () => ({
  type: constants.campaigns.CLEAR_CAMPAIGNS,
});

const addCampaignAction = (body, history) => ({
  type: constants.campaigns.ADD_CAMPAIGN,
  body,
  history,
});

const getCampaignAction = id => ({
  type: constants.campaigns.GET_CAMPAIGN,
  id,
});

const setCampaignAction = campaign => ({
  type: constants.campaigns.SET_CAMPAIGN,
  campaign,
});

const editCampaignAction = (id, body, history) => ({
  type: constants.campaigns.EDIT_CAMPAIGN,
  id,
  body,
  history,
});

const getCampaignPotentialAudience = body => ({
  type: constants.campaigns.GET_CAMPAIGN_POTENTIAL_AUDIENCE,
  body,
});

const setCampaignPotentialAudience = result => ({
  type: constants.campaigns.SET_CAMPAIGN_POTENTIAL_AUDIENCE,
  result,
});

const setCampaignPotentialAudienceLoadingAction = flag => ({
  type: constants.campaigns.SET_CAMPAIGN_POTENTIAL_AUDIENCE_LOADING,
  flag,
});

export const campaignsActions = {
  setCampaignsLoadingAction,
  getCampaignsAction,
  setCampaignsAction,
  clearCampaignsAction,
  addCampaignAction,
  getCampaignAction,
  setCampaignAction,
  editCampaignAction,
  getCampaignPotentialAudience,
  setCampaignPotentialAudience,
  setCampaignPotentialAudienceLoadingAction,
};
