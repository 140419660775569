import React from 'react';
import styles from './segments.module.scss';
import Select from '../form/select/select';
import ButtonSimple from '../buttons/buttonSimple/buttonSimple';
import {
  CONDITION_TYPE_BUSINESS_STATUS,
  CONDITION_TYPE_LOCATION,
  CONDITION_TYPE_MEMBERSHIP,
  CONDITION_TYPE_MONEY_SPENT,
  CONDITION_TYPE_SERVICE,
} from '../../pages/campaignNotifications/constants';
import MembershipConditionSegment from './conditionSegments/membershipSegment';
import BusinessStatusConditionSegment from './conditionSegments/businessStatusSegment';
import ServiceSegment from './conditionSegments/serviceSegment';
import LocationSegment from './conditionSegments/locationSegment';
import MoneySpentSegment from './conditionSegments/moneySpentSegment';
import LabelElement from '../form/_elements/labelElement/labelElement';

const Segment = ({
  segmentId,
  condition,
  conditionTypes,
  handleSegmentEdit,
  handleSegmentRemove,
  availableLocations,
  availableMemberships,
  frequencyTypes,
  disableEdit,
}) => {
  const renderSegmentType = () => {
    const conditionType = condition?.type;
    switch (conditionType) {
      case CONDITION_TYPE_MEMBERSHIP:
        return (
          <MembershipConditionSegment
            segmentId={segmentId}
            condition={condition}
            onChange={handleSegmentEdit}
            availableMemberships={availableMemberships}
          />
        );
      case CONDITION_TYPE_BUSINESS_STATUS:
        return (
          <BusinessStatusConditionSegment
            segmentId={segmentId}
            condition={condition}
            onChange={handleSegmentEdit}
          />
        );
      case CONDITION_TYPE_SERVICE:
        return (
          <ServiceSegment
            segmentId={segmentId}
            condition={condition}
            onChange={handleSegmentEdit}
            frequencyTypes={frequencyTypes}
          />
        );
      case CONDITION_TYPE_LOCATION:
        return (
          <LocationSegment
            segmentId={segmentId}
            condition={condition}
            onChange={handleSegmentEdit}
            availableLocations={availableLocations}
            frequencyTypes={frequencyTypes}
          />
        );
      case CONDITION_TYPE_MONEY_SPENT:
        return (
          <MoneySpentSegment
            condition={condition}
            onChange={handleSegmentEdit}
          />
        );
      default:
        return 'Nėra tokio segmento';
    }
  };

  return (
    <div className={styles.segment}>
      <div className={styles.segmentHeader}>
        <div className={styles.segmentTypeSelect}>
          <div className={styles.labelContainer}>
            <LabelElement label="Formuoti pagal:" htmlFor="type" />
          </div>
          <Select
            name="type"
            options={conditionTypes}
            selectedValue={condition.type}
            onChange={e =>
              handleSegmentEdit({ name: e.target.name, value: e.target.value })
            }
            disabled={disableEdit}
          />
        </div>
        {!disableEdit && (
          <ButtonSimple
            type="remove-red"
            onClick={() => handleSegmentRemove()}
            style={{ marginLeft: 'auto', marginBottom: '0.5rem' }}
          >
            Pašalinti
          </ButtonSimple>
        )}
      </div>
      {renderSegmentType()}
    </div>
  );
};

export default Segment;
