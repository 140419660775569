import React, { createContext, useState } from 'react';

export const CampaignNotificationsContext = createContext();

export const CampaignNotificationsProvider = ({ children }) => {
  const [filterBody, setFilterBody] = useState();
  const [statusFilter, setStatusFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState([]);
  const [titleFilter, setTitleFilter] = useState([]);
  const [sendDateLtFilter, setSendDateLtFilter] = useState();
  const [sendDateGtFilter, setSendDateGtFilter] = useState();
  const [sortOrder, setSortOrder] = useState('asc');

  const prepareConditionsForPayload = conditions => {
    const preparedConditions = conditions?.map(conditionGroup => {
      return conditionGroup?.map(segment => {
        return {
          ...segment,
          ...(segment?.from_value && {
            from_value: parseFloat(segment.from_value) * 100,
          }),
          ...(segment?.to_value && {
            to_value: parseFloat(segment.to_value) * 100,
          }),
          ...(segment?.frequency && {
            frequency: parseInt(segment.frequency),
          }),
        };
      });
    });
    return preparedConditions;
  };

  const prepareConditionsForDisplay = conditions => {
    const preparedConditions = conditions?.map(conditionGroup => {
      return conditionGroup?.map(segment => {
        return {
          ...segment,
          ...(segment?.from_value && {
            from_value: parseFloat(segment.from_value) / 100,
          }),
          ...(segment?.to_value && {
            to_value: parseFloat(segment.to_value) / 100,
          }),
          ...(segment?.frequency && {
            frequency: parseInt(segment.frequency),
          }),
        };
      });
    });
    return preparedConditions;
  };

  return (
    <CampaignNotificationsContext.Provider
      value={{
        filterBody,
        setFilterBody,
        statusFilter,
        setStatusFilter,
        typeFilter,
        setTypeFilter,
        titleFilter,
        setTitleFilter,
        sendDateLtFilter,
        setSendDateLtFilter,
        sendDateGtFilter,
        setSendDateGtFilter,
        sortOrder,
        setSortOrder,
        prepareConditionsForPayload,
        prepareConditionsForDisplay,
      }}
    >
      {children}
    </CampaignNotificationsContext.Provider>
  );
};
