import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import styles from './confirmCampaignModal.module.scss';
import ModalLayout from '../_modalLayout/modalLayout';
import { Button } from '../../../components';

const ConfirmCampaignModal = ({
  title,
  type,
  date,
  onConfirm,
  onCancel,
  onFetchPotentialAudience,
  campaigns: { potentialAudienceResult },
}) => {
  const handleConfirm = () => {
    onConfirm();
    onCancel();
  };

  useEffect(() => {
    onFetchPotentialAudience();
  }, []);

  const computedDescription = useCallback(() => {
    return `Ar tikrai norite išsiųsti ${type} tipo kampaniją, ${potentialAudienceResult} klientams suplanuotu laiku ${date}?`;
  }, [potentialAudienceResult]);

  return (
    <ModalLayout title={title} onCancel={onCancel}>
      <p className={styles.desc} id="modal__desc">
        {computedDescription()}
      </p>

      <div className={styles.actions}>
        <Button color="outline" onClick={onCancel}>
          Ne
        </Button>
        <Button color="primary" onClick={handleConfirm}>
          Taip
        </Button>
      </div>
    </ModalLayout>
  );
};

const mapStateToProps = state => ({
  campaigns: state.campaigns,
});

export default connect(mapStateToProps)(ConfirmCampaignModal);
