import React from 'react';
import styles from '../segments.module.scss';
import Input from '../../form/input/input';

const MoneySpentSegment = ({ condition, onChange }) => {
  return (
    <div className={`${styles.segmentForm} ${styles.moneySpent}`}>
      <div className={styles.inputsRow}>
        <Input
          name="from_value"
          label="Nuo (EUR)"
          type="number"
          min="0"
          step="0.01"
          placeholder="0.00"
          onChange={e =>
            onChange({ name: e.target.name, value: e.target.value })
          }
          value={condition.from_value}
        />
        <Input
          name="to_value"
          label="Iki (EUR)"
          type="number"
          min="0"
          step="0.01"
          placeholder="0.00"
          onChange={e =>
            onChange({ name: e.target.name, value: e.target.value })
          }
          value={condition.to_value}
        />
      </div>
    </div>
  );
};

export default MoneySpentSegment;
