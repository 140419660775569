import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { actions } from '../../state';
import ConfirmModal from './confirmModal/confirmModal';
import DefaultModal from './defaultModal/defaultModal';
import QrCodesAllModal from './qrCodesAllModal/qrCodesAllModal';
import QrCodeModal from './qrCodeModal/qrCodeModal';
import ExportModal from './exportModal/exportModal';
import ConfirmCampaignModal from './confirmCampaign/confirmCampaignModal';

const GlobalModal = ({
  modals: {
    confirmModal,
    defaultModal,
    exportModal,
    qrCodesAllModal,
    qrCodeModal,
    confirmCampaignModal,
    children,
    title,
    desc,
    onConfirm,
    withScroll,
    onFetchPotentialAudience,
    type,
    date,
  },
  dispatch,
  location: { pathname },
}) => {
  useEffect(() => {
    if (
      confirmModal ||
      defaultModal ||
      exportModal ||
      qrCodesAllModal ||
      qrCodeModal ||
      confirmCampaignModal
    ) {
      dispatch(actions.modals.clearModals());
    }
    // eslint-disable-next-line
  }, [pathname]);

  const handleCancel = () => {
    dispatch(actions.modals.clearModals());
    dispatch(actions.download.cleanDownload());
  };

  switch (true) {
    case defaultModal:
      return (
        <DefaultModal
          children={children}
          title={title}
          onConfirm={onConfirm}
          onCancel={handleCancel}
          withScroll={withScroll}
        />
      );
    case confirmModal:
      return (
        <ConfirmModal
          title={title}
          desc={desc}
          onConfirm={onConfirm}
          onCancel={handleCancel}
        />
      );
    case exportModal:
      return <ExportModal title={title} onCancel={handleCancel} />;
    case qrCodesAllModal:
      return <QrCodesAllModal title={title} />;
    case qrCodeModal:
      return (
        <QrCodeModal
          title={title}
          children={children}
          desc={desc}
          onCancel={handleCancel}
        />
      );
    case confirmCampaignModal:
      return (
        <ConfirmCampaignModal
          title={title}
          children={children}
          type={type}
          date={date}
          onConfirm={onConfirm}
          onCancel={handleCancel}
          onFetchPotentialAudience={onFetchPotentialAudience}
        />
      );
    default:
      return null;
  }
};

const mapStateToProps = state => ({
  modals: state.modals,
});

GlobalModal.propTypes = {
  modals: PropTypes.shape({
    confirmModal: PropTypes.bool.isRequired,
    defaultModal: PropTypes.bool.isRequired,
    qrCodesAllModal: PropTypes.bool.isRequired,
    qrCodeModal: PropTypes.bool.isRequired,
    confirmCampaignModal: PropTypes.bool.isRequired,
    children: PropTypes.node,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    onConfirm: PropTypes.func,
    onFetchPotentialAudience: PropTypes.func,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default compose(withRouter, connect(mapStateToProps))(GlobalModal);
