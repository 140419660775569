export const campaignsConstants = {
  SET_CAMPAIGNS_LOADING: 'campaigns/SET_CAMPAIGNS_LOADING',
  GET_CAMPAIGNS: 'campaigns/GET_CAMPAIGNS',
  SET_CAMPAIGNS: 'campaigns/SET_CAMPAIGNS',
  CLEAR_CAMPAIGNS: 'campaigns/CLEAR_CAMPAIGNS',
  ADD_CAMPAIGN: 'campaigns/ADD_CAMPAIGN',
  GET_CAMPAIGN: 'campaigns/GET_CAMPAIGN',
  SET_CAMPAIGN: 'campaigns/SET_CAMPAIGN',
  EDIT_CAMPAIGN: 'campaigns/EDIT_CAMPAIGN',
  GET_CAMPAIGN_POTENTIAL_AUDIENCE: 'campaigns/GET_CAMPAIGN_POTENTIAL_AUDIENCE',
  SET_CAMPAIGN_POTENTIAL_AUDIENCE: 'campaigns/SET_CAMPAIGN_POTENTIAL_AUDIENCE',
  SET_CAMPAIGN_POTENTIAL_AUDIENCE_LOADING:
    'campaigns/SET_CAMPAIGNS_POTENTIAL_AUDIENCE_LOADING',
};
