import React from 'react';
import styles from '../segments.module.scss';
import MultiselectAll from '../../form/multiselectAll/multiselectAll';

const MembershipConditionSegment = ({
  segmentId,
  condition,
  onChange,
  availableMemberships,
}) => {
  const memberships = condition?.values;

  return (
    <div className={styles.segmentForm}>
      <div className={styles.selectWrapper}>
        <MultiselectAll
          array={availableMemberships}
          type="object"
          name="title_lt"
          value="id"
          valueType="number"
          allText="Visi klientai"
          customId={segmentId}
          state={memberships}
          setState={value => {
            onChange({ name: 'values', value });
          }}
        />
      </div>
    </div>
  );
};

export default MembershipConditionSegment;
