import React, { useCallback, useEffect } from 'react';
import styles from './campaignNotifications.module.scss';
import SegmentsGroup from '../../components/segments/segmentsGroup';
import Segment from '../../components/segments/segment';
import { ButtonSimple } from '../../components';
import {
  CONDITION_TYPE_BUSINESS_STATUS,
  CONDITION_TYPE_LOCATION,
  CONDITION_TYPE_MEMBERSHIP,
  CONDITION_TYPE_MONEY_SPENT,
  CONDITION_TYPE_SERVICE,
  CONDITION_TYPE_MEMBERSHIP_NAME,
  CONDITION_TYPE_BUSINESS_STATUS_NAME,
  CONDITION_TYPE_SERVICE_NAME,
  CONDITION_TYPE_LOCATION_NAME,
  CONDITION_TYPE_MONEY_SPENT_NAME,
  membershipConditionSegment,
  businessStatusConditionSegment,
  serviceConditionSegment,
  locationConditionSegment,
  moneySpentConditionSegment,
} from './constants';
import SegmentDivision from '../../components/segments/segmentDivision';
import { connect } from 'react-redux';
import { actions } from '../../state';

const CampaignNotificationSegments = ({
  dispatch,
  conditions,
  setConditions,
  availableLocations,
  locationsLoading,
  disableEdit,
  memberships: { memberships },
}) => {
  const conditionTypes = [
    {
      name: CONDITION_TYPE_MEMBERSHIP_NAME,
      value: CONDITION_TYPE_MEMBERSHIP,
    },
    {
      name: CONDITION_TYPE_BUSINESS_STATUS_NAME,
      value: CONDITION_TYPE_BUSINESS_STATUS,
    },
    {
      name: CONDITION_TYPE_SERVICE_NAME,
      value: CONDITION_TYPE_SERVICE,
    },
    {
      name: CONDITION_TYPE_LOCATION_NAME,
      value: CONDITION_TYPE_LOCATION,
    },
    {
      name: CONDITION_TYPE_MONEY_SPENT_NAME,
      value: CONDITION_TYPE_MONEY_SPENT,
    },
  ];

  const frequencyTypes = [
    {
      name: '1',
      value: 1,
    },
    {
      name: '2',
      value: 2,
    },
    {
      name: '3',
      value: 3,
    },
    {
      name: '4',
      value: 4,
    },
    {
      name: '5',
      value: 5,
    },
  ];

  const defaultConditionSegment = membershipConditionSegment;

  const defaultConditionGroup = [defaultConditionSegment];

  const handleConditionGroupAdd = () => {
    setConditions(conditions => [...conditions, defaultConditionGroup]);
  };

  const handleConditionGroupRemove = i => {
    if (i === 0) {
      const newConditions = conditions.slice(1);
      setConditions(newConditions);
    } else {
      const newConditions = conditions.filter((_, index) => i !== index);
      setConditions(newConditions);
    }
  };

  const handleConditionSegmentAdd = i => {
    setConditions(conditions =>
      conditions.map((conditionGroup, index) => {
        return index === i
          ? [...conditionGroup, defaultConditionSegment]
          : conditionGroup;
      })
    );
  };

  const handleConditionSegmentRemove = (i, j) => {
    const isLastSegmentToRemove = conditions[i].length === 1;

    if (isLastSegmentToRemove) {
      handleConditionGroupRemove(i);
    } else {
      const newConditionGroup = conditions[i].filter((_, index) => index !== j);
      setConditions(conditions =>
        conditions.map((conditionGroup, index) => {
          return index === i ? newConditionGroup : conditionGroup;
        })
      );
    }
  };

  const getEmptySegmentCondition = type => {
    switch (type) {
      case CONDITION_TYPE_MEMBERSHIP:
        return membershipConditionSegment;
      case CONDITION_TYPE_BUSINESS_STATUS:
        return businessStatusConditionSegment;
      case CONDITION_TYPE_SERVICE:
        return serviceConditionSegment;
      case CONDITION_TYPE_LOCATION:
        return locationConditionSegment;
      case CONDITION_TYPE_MONEY_SPENT:
        return moneySpentConditionSegment;
      default:
        return membershipConditionSegment;
    }
  };

  const handleConditionSegmentEdit = (payload, i, j) => {
    const name = payload.name;

    const isTypeChange = name === 'type';
    const value = isTypeChange ? parseInt(payload.value) : payload.value;

    setConditions(conditions =>
      conditions.map((conditionGroup, index) => {
        if (index === i) {
          return conditionGroup?.map((segment, segmentIndex) => {
            if (isTypeChange) {
              return segmentIndex === j
                ? getEmptySegmentCondition(value)
                : segment;
            } else {
              return segmentIndex === j
                ? { ...segment, [name]: value }
                : segment;
            }
          });
        }
        return conditionGroup;
      })
    );
  };

  const fetchLocations = useCallback(() => {
    const body = {
      order: [
        {
          by: 'full_address',
          order: 'asc',
        },
      ],
    };
    dispatch(actions.locations.getLocationsAction(body));
  }, [dispatch]);

  const fetchMemberships = useCallback(() => {
    dispatch(actions.memberships.getMembershipsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  useEffect(() => {
    fetchMemberships();
  }, [fetchMemberships]);

  return (
    <div className={styles.segmentsContainer}>
      {conditions?.map((conditionGroup, i) => (
        <div key={`group-${i}`} style={{ width: '100%' }}>
          <SegmentsGroup>
            {conditionGroup.map((condition, j) => (
              <div key={`segment-${j}`} style={{ width: '100%' }}>
                <Segment
                  segmentId={`segment-${i}-${j}`}
                  condition={condition}
                  conditionTypes={conditionTypes}
                  handleSegmentEdit={value =>
                    handleConditionSegmentEdit(value, i, j)
                  }
                  handleSegmentRemove={() => handleConditionSegmentRemove(i, j)}
                  availableLocations={availableLocations}
                  availableMemberships={memberships}
                  frequencyTypes={frequencyTypes}
                  disableEdit={disableEdit}
                />
                {conditionGroup?.length > 1 &&
                  !(conditionGroup.length - 1 === j) && (
                    <SegmentDivision type="or" />
                  )}
              </div>
            ))}
            {!disableEdit && (
              <ButtonSimple
                type="add"
                onClick={() => handleConditionSegmentAdd(i)}
                style={{
                  marginLeft: 'auto',
                  marginRight: '2rem',
                  marginBottom: '2rem',
                }}
              >
                Pridėti ARBA sąlygą
              </ButtonSimple>
            )}
          </SegmentsGroup>
          {conditions?.length > 1 && !(conditions.length - 1 === i) && (
            <SegmentDivision type="and" />
          )}
        </div>
      ))}
      {!disableEdit && (
        <ButtonSimple
          type="add"
          onClick={() => handleConditionGroupAdd()}
          style={{ marginLeft: 'auto' }}
        >
          Pridėti IR sąlygą
        </ButtonSimple>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  availableLocations: state.locations.locations,
  locationsLoading: state.locations.loading,
  memberships: state.memberships,
});

export default connect(mapStateToProps)(CampaignNotificationSegments);
