import React, { useContext, useCallback, useEffect } from 'react';
import { config } from '../../api/config';
import { actions } from '../../state';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  mapCampaignStatus,
  mapCampaignStatusColor,
  mapCampaignType,
  mapCampaignTypeColor,
} from '../../utils/index';

import {
  Header,
  Title,
  Button,
  Table,
  TableRow,
  TableCell,
  SelectFilter,
  DateFilter,
  HeaderActions,
  ButtonSimple,
} from '../../components';

import { formatDate, isFilterActive, generateHeader } from '../../utils';
import { CampaignNotificationsContext } from './campaignNotifications.context';

const CampaignNotifications = ({
  dispatch,
  campaigns: { campaigns, total, header, loading },
  xlsLoading,
}) => {
  const {
    filterBody,
    setFilterBody,
    statusFilter,
    setStatusFilter,
    typeFilter,
    setTypeFilter,
    titleFilter,
    setTitleFilter,
    sendDateLtFilter,
    setSendDateLtFilter,
    sendDateGtFilter,
    setSendDateGtFilter,
    sortOrder,
    setSortOrder,
  } = useContext(CampaignNotificationsContext);

  const fetchCampaigns = useCallback(
    data => {
      dispatch(actions.campaigns.getCampaignsAction(data));
    },
    [dispatch]
  );

  const clearCampaigns = useCallback(() => {
    dispatch(actions.campaigns.clearCampaignsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchCampaigns(filterBody);
    return () => clearCampaigns();
  }, [fetchCampaigns, clearCampaigns, filterBody]);

  const handleSubmit = name => {
    const generatedFilter = [];

    if (statusFilter.length) {
      generatedFilter.push({ field: 'status', value: statusFilter });
    }
    if (typeFilter.length) {
      generatedFilter.push({ field: 'type', value: typeFilter });
    }
    if (titleFilter.length) {
      generatedFilter.push({ field: 'title', value: titleFilter });
    }
    if (sendDateLtFilter) {
      generatedFilter.push({
        field: 'send_at',
        expression: 'lte',
        value: sendDateLtFilter,
        type: 'timestamp',
      });
    }
    if (sendDateGtFilter) {
      generatedFilter.push({
        field: 'send_at',
        expression: 'gte',
        value: sendDateGtFilter,
        type: 'timestamp',
      });
    }

    if (name) {
      setFilterBody({
        filter: {
          and: generatedFilter,
        },
        order: [
          {
            by: name,
            order: sortOrder,
          },
        ],
      });
    } else {
      setFilterBody();
    }
  };

  const tableHeader = [
    <SelectFilter
      title="Statusas"
      name="status"
      valueType="number"
      array={generateHeader(header.status, mapCampaignStatus)}
      filterState={statusFilter}
      setFilterState={setStatusFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', statusFilter)}
    />,
    <SelectFilter
      title="Tipas"
      name="type"
      valueType="number"
      array={generateHeader(header.type, mapCampaignType)}
      filterState={typeFilter}
      setFilterState={setTypeFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', typeFilter)}
    />,
    'Kampanijos pavadinimas',
    <DateFilter
      title="Išsiuntimo pradžios data"
      name="send_at"
      lt={sendDateLtFilter}
      setLt={setSendDateLtFilter}
      gt={sendDateGtFilter}
      setGt={setSendDateGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('send_at', sendDateLtFilter, sendDateGtFilter)}
    />,
  ];

  const clearFilters = () => {
    setStatusFilter([]);
    setTypeFilter([]);
    setTitleFilter([]);
    setSendDateLtFilter();
    setSendDateGtFilter();

    handleSubmit();
  };

  const renderTable = () =>
    campaigns.map(({ id, status, type, title, send_at }) => (
      <TableRow url={config.APP_PATHS.editCampaignNotification(id)} key={id}>
        <TableCell>
          <span style={{ color: mapCampaignStatusColor(status) }}>
            {mapCampaignStatus(status)}
          </span>
        </TableCell>
        <TableCell>
          <span style={{ color: mapCampaignTypeColor(type) }}>
            {mapCampaignType(type)}
          </span>
        </TableCell>
        <TableCell>{title}</TableCell>
        <TableCell>{send_at && formatDate(send_at)}</TableCell>
      </TableRow>
    ));

  // const xlsExport = () => {
  //   const filename = formatFilename(config.PAGES.businessClients);
  //   dispatch(
  //     actions.businessClients.exportBusinessClientsAction(filename, filterBody)
  //   );
  // };

  return (
    <section>
      <Header>
        <Title total={total}>{config.PAGES.campaignNotifications}</Title>
        <HeaderActions>
          {filterBody && (
            <ButtonSimple type="filter" onClick={clearFilters}>
              Išvalyti filtrus
            </ButtonSimple>
          )}
          {
            <Button
              color="primary"
              url={config.APP_PATHS.addCampaignNotification}
            >
              Pridėti
            </Button>
          }
          {/* <ButtonDownload onClick={xlsExport} loading={xlsLoading} /> */}
        </HeaderActions>
      </Header>
      <Table
        header={tableHeader}
        isEmpty={!campaigns.length}
        filter={true}
        loading={loading}
      >
        {renderTable()}
      </Table>
    </section>
  );
};

const mapStateToProps = state => ({
  campaigns: state.campaigns,
  xlsLoading: state.download.xlsLoading,
});

CampaignNotifications.propTypes = {
  dispatch: PropTypes.func.isRequired,
  campaigns: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    total: PropTypes.number.isRequired,
    campaigns: PropTypes.array.isRequired,
    header: PropTypes.shape({
      status: PropTypes.arrayOf(PropTypes.number),
      type: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
  }).isRequired,
  xlsLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(CampaignNotifications);
