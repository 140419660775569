import React from 'react';
import styles from '../segments.module.scss';
import MultiselectAll from '../../form/multiselectAll/multiselectAll';
import {
  campaignBusinessStatuses,
  mapCampaignBusinessStatus,
} from '../../../utils';

const BusinessStatusConditionSegment = ({ segmentId, condition, onChange }) => {
  const statuses = condition?.values;

  return (
    <div className={styles.segmentForm}>
      <div className={styles.selectWrapper}>
        <MultiselectAll
          array={campaignBusinessStatuses}
          type="array"
          renderName={mapCampaignBusinessStatus}
          valueType="number"
          allText="Visi"
          customId={segmentId}
          state={
            campaignBusinessStatuses?.length === statuses?.length
              ? []
              : statuses
          }
          setState={value => {
            onChange({ name: 'values', value });
          }}
        />
      </div>
    </div>
  );
};

export default BusinessStatusConditionSegment;
