import InActive from '../../assets/icons/inactive.svg';
import Active from '../../assets/icons/active.svg';
import {
  INVOICE_STATUS_NOT_PAID,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PARTIALLY_PAID,
} from '../../pages/invoices/constants';
import {
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_STATUS_ACTIVE_NAME,
  CAMPAIGN_STATUS_DRAFT,
  CAMPAIGN_STATUS_DRAFT_NAME,
  CAMPAIGN_STATUS_ERROR,
  CAMPAIGN_STATUS_ERROR_NAME,
  CAMPAIGN_STATUS_SENT,
  CAMPAIGN_STATUS_SENT_NAME,
  CAMPAIGN_TYPE_PUSH,
  CAMPAIGN_TYPE_SMS,
  CAMPAIGN_TYPE_SMS_PUSH,
} from '../../pages/campaignNotifications/constants';

export const mapUserStatus = status => {
  switch (status) {
    case 0:
      return 'Deaktyvuotas';
    case 1:
      return 'Aktyvus';
    default:
      return '-';
  }
};

export const mapEmployeeType = type => {
  switch (type) {
    case 1401:
      return 'Programėlė';
    case 1402:
      return 'Kortelė';
    default:
      return 'Programėlė';
  }
};

export const mapBusinessInvoiceStatus = status => {
  switch (status) {
    case 1:
      return 'Apmokėta';
    default:
      return 'Neapmokėta';
  }
};

export const mapOnOffStatus = status => {
  switch (status) {
    case 0:
      return 'Išjungtas';
    case 1:
      return 'Įjungtas';
    default:
      return '-';
  }
};

export const serviceTypes = [21, 22, 23, 24, 25, 26, 27, 28, 29, 30];

export const mapServiceTypes = type => {
  switch (type) {
    case 21:
      return 'Savitarna';
    case 22:
      return 'Automatinė bekontaktė';
    case 23:
      return 'Automatinė tekstilinė';
    case 24:
      return 'Dulkių siurblys';
    case 25:
      return 'Šluostės';
    case 26:
      return 'Langų skystis';
    case 27:
      return 'Kilimėlių plovimas';
    case 28:
      return 'Išmanus dulkių siurblys';
    case 29:
      return 'Padangų juodinimas';
    case 30:
      return 'Sausinimo rankšluostis';
    default:
      return '-';
  }
};

export const selfServiceTypes = [101, 102, 103, 104, 105];

export const mapSelfServiceTypes = type => {
  switch (type) {
    case 101:
      return 'Atmirkymas';
    case 102:
      return 'Plovimas';
    case 103:
      return 'Skalavimas';
    case 104:
      return 'Vaškavimas';
    case 105:
      return 'Džiovinimas';
    default:
      return '-';
  }
};

export const serviceTypesNames = [
  'self_service_washing',
  'automatic_textile_washing',
  'automatic_contactless_washing',
  'vacuum_cleaner',
  'smart_vacuum_cleaner',
  'car_mat_cleaner',
  'paper_towel_dispenser',
  'windshield_washer_liquid_dispenser',
  'tyre_blackener',
  'towel_dispenser',
];

export const mapServiceTypesNames = location => {
  switch (location) {
    case 'self_service_washing':
      return 'Savitarna';
    case 'automatic_textile_washing':
      return 'Automatinė tekstilinė';
    case 'automatic_contactless_washing':
      return 'Automatinė bekontaktė';
    case 'vacuum_cleaner':
      return 'Dulkių siurblys';
    case 'smart_vacuum_cleaner':
      return 'Išmanus dulkių siurblys';
    case 'car_mat_cleaner':
      return 'Kilimėlių plovimo automatas';
    case 'paper_towel_dispenser':
      return 'Šluosčių pardavimo automatas';
    case 'windshield_washer_liquid_dispenser':
      return 'Langų skysčio papildymo automatas';
    case 'tyre_blackener':
      return 'Padangų juodinimo aparatas';
    case 'towel_dispenser':
      return 'Sausinimo rankšluostis';
    default:
      return '';
  }
};

export const membershipTypes = [30, 31, 32, 33];

export const mapMembershipType = type => {
  switch (type) {
    case 30:
      return 'Pagal užsakymą';
    case 31:
      return 'Sidabrinis';
    case 32:
      return 'Auksinis';
    case 33:
      return 'Deimantinis';
    case 34:
      return 'Baltas';
    case 35:
      return 'Žalias';
    case 36:
      return 'Raudonas';
    case 38:
      return 'Pagrindinis';
    case 39:
      return 'Šešėlinis';
    default:
      return '-';
  }
};

export const paymentTypes = [91, 92];

export const mapPaymentType = type => {
  switch (type) {
    case 91:
      return 'Privatus';
    case 92:
      return 'Verslas';
    default:
      return '-';
  }
};

export const mapPaymentMethod = type => {
  switch (type) {
    case 371:
      return 'Mokėjimo kortelė';
    case 372:
      return 'Virtuali piniginė';
    case 373:
      return 'Sutartis';
    default:
      return '-';
  }
};

export const discountStatuses = [171, 172, 173, 174];

export const mapDiscountStatus = status => {
  switch (status) {
    case 171:
      return 'Laukiama';
    case 172:
      return 'Galioja';
    case 173:
      return 'Pasibaigusi';
    case 174:
      return 'Deaktyvuota';
    default:
      return '-';
  }
};

export const discountGroups = [188, 187, 186, 185];

export const mapDiscountGroup = group => {
  switch (group) {
    case 181:
      return 'Visi klientai';
    case 185:
      return 'Klientai neturintys paso';
    case 186:
      return 'Baltas';
    case 187:
      return 'Žalias';
    case 188:
      return 'Raudonas';
    default:
      return '-';
  }
};

export const businessStatuses = [1951, 1952, 1953];

export const mapBusinessStatus = status => {
  switch (status) {
    case 1951:
      return 'Su kontraktu';
    case 1952:
      return 'Be kontrakto';
    case 1953:
      return 'Privatus';
    default:
      return '-';
  }
};

export const campaignBusinessStatuses = [1951, 1952, 1953];

export const mapCampaignBusinessStatus = status => {
  switch (status) {
    case 1951:
      return 'Verslas su sutartimi';
    case 1952:
      return 'Verslas be sutarties';
    case 1953:
      return 'Privatus';
    default:
      return '-';
  }
};

export const serviceOptionTypes = [201, 202, 203, 204];

export const mapServiceOptionType = status => {
  switch (status) {
    case 201:
      return 'ORANGE';
    case 202:
      return 'PRO';
    case 203:
      return 'VIP';
    case 204:
      return 'ELITE';
    default:
      return '-';
  }
};

export const mapDeviceTypes = type => {
  switch (type) {
    case 201:
    case 'self_service_washing':
      return 'Boksas';
    case 202:
      return 'Atviras boksas';
    case 211:
    case 'automatic_textile_washing':
      return 'Tekstilinė';
    case 212:
    case 'automatic_contactless_washing':
      return 'Bekontaktė';
    case 221:
    case 'vacuum_cleaner':
      return 'Siurblys';
    case 222:
    case 'smart_vacuum_cleaner':
      return 'Siurblys';
    case 231:
    case 'car_mat_cleaner':
      return 'Kilimėliai';
    case 241:
    case 'paper_towel_dispenser':
      return 'Šluostės';
    case 251:
    case 'windshield_washer_liquid_dispenser':
      return 'Langų skystis';
    case 261:
    case 'tyre_blackener':
      return 'Juodinimas';
    case 271:
    case 'towel_dispenser':
      return 'Rankšluostis';
    default:
      return '-';
  }
};

export const mapServiceToDeviceType = service => {
  switch (service) {
    case 'self_service_washing':
      return 201;
    case 'automatic_textile_washing':
      return 211;
    case 'automatic_contactless_washing':
      return 212;
    case 'vacuum_cleaner':
      return 221;
    case 'smart_vacuum_cleaner':
      return 222;
    case 'car_mat_cleaner':
      return 231;
    case 'paper_towel_dispenser':
      return 241;
    case 'windshield_washer_liquid_dispenser':
      return 251;
    case 'tyre_blackener':
      return 261;
    case 'towel_dispenser':
      return 271;
    default:
      return 0;
  }
};

export const orderStatuses = [351, 352, 353, 354, 355, 356];

export const mapOrderStatus = type => {
  switch (type) {
    case 351:
      return 'Vykdomas';
    case 352:
      return 'Laukiama mokėjimo';
    case 353:
      return 'Įvykdytas';
    case 354:
      return 'Atmestas';
    case 355:
      return 'Sugedęs';
    case 356:
      return 'Dalinai apmokėtas';
    default:
      return '-';
  }
};

export const membershipOrderStatuses = [551, 552, 553, 554, 555];

export const mapMembershipOrderStatus = status => {
  switch (status) {
    case 551:
      return 'Laukiama mokėjimo';
    case 552:
      return 'Aktyvus';
    case 553:
      return 'Pasibaigęs';
    case 554:
      return 'Deaktyvuotas';
    case 555:
      return 'Mokėjimas nepavyko';
    default:
      return '-';
  }
};

export const mapClientPromotions = status => {
  switch (status) {
    case 0:
      return 'Nesutinka';
    case 1:
      return 'Sutinka';
    default:
      return '-';
  }
};

export const mapClientCities = id => {
  // TODO: If BE returns city Id then map to values, else delete this
};

export const boxStatuses = [0, 1, 2, 4, 10, 11, 12];

export const mapBoxStatus = status => {
  switch (status) {
    case 0:
      return 'Laisva';
    case 1:
      return 'Užimta';
    case 2:
      return 'Klaida';
    case 4:
      return 'Užbaigta';
    case 5:
      return 'Užimta - kortele';
    case 10:
      return 'Nežinoma';
    case 11:
      return 'Išjungta';
    case 12:
      return 'Nepasiekiama';
    default:
      return '-';
  }
};

export const mapServicesStatus = status => {
  switch (status) {
    case 0:
      return 'Išjungta';
    case 1:
      return 'Įjungta';
    default:
      return '-';
  }
};

export const mapDiscountStatusColor = status => {
  switch (status) {
    case 171:
      return 'var(--color__status-orange)';
    case 172:
      return 'var(--color__status-green)';
    default:
      return '-';
  }
};

export const mapMembershipOrderStatusColor = status => {
  switch (status) {
    case 551:
      return 'var(--color__status-dark-green)';
    case 552:
      return 'var(--color__status-green)';
    case 555:
      return 'var(--color__status-red)';
    default:
      return 'var(--color__status-dark-grey)';
  }
};

export const mapUserStatusColor = status => {
  switch (status) {
    case 1:
      return 'var(--color__status-green)';
    default:
      return 'var(--color__status-dark-grey)';
  }
};

export const mapBusinessInvoiceStatusColor = status => {
  switch (status) {
    case 1:
      return 'var(--color__status-dark-grey)';
    default:
      return 'var(--color__status-orange)';
  }
};

export const mapOrderStatusColor = type => {
  switch (type) {
    case 351:
      return 'var(--color__status-blue)';
    case 352:
      return 'var(--color__status-green)';
    case 354:
      return 'var(--color__status-red)';
    case 355:
      return 'var(--color__status-red)';
    case 356:
      return 'var(--color__status-orange)';
    default:
      return 'var(--color__status-dark-grey)';
  }
};

export const checkStatusColor = status => {
  switch (status) {
    case 0:
      return 'var(--color__grey)';
    case 1:
      return 'var(--color__success)';
    default:
      return '';
  }
};

export const checkBoxColor = status => {
  switch (status) {
    case 0:
      return 'var(--color__success)';
    case 1:
      return 'var(--color__warning';
    case 2:
    case 4:
    case 10:
    case 11:
    case 12:
      return 'var(--color__grey';
    default:
      return '';
  }
};

export const mapAdyenStatus = status => {
  switch (status) {
    case 450:
      return 'Pradinė rezervacija';
    case 451:
      return 'Ruošiamas';
    case 452:
      return 'Užklausa išsiųsta';
    case 453:
      return 'Rezervuota';
    case 454:
      return 'Nuskaityta';
    case 455:
      return 'Nuskaitymas nepavyko';
    case 456:
      return 'Atšauktas';
    case 457:
      return 'Atšaukimas nepavyko';
    case 458:
      return 'Didina rezervacija';
    case 458:
      return 'Nuskaitymas užklaustas';
    default:
      return '';
  }
};

export const mapDeviceStatusIcon = status => {
  switch (status) {
    case 0:
      return Active;
    case 1:
      return Active;
    case 4:
      return Active;
    case 5:
      return Active;
    default:
      return InActive;
  }
};

export const mapInvoicePaymentStatus = status => {
  switch (status) {
    case INVOICE_STATUS_NOT_PAID:
      return 'Neapmokėta';
    case INVOICE_STATUS_PAID:
      return 'Apmokėta';
    case INVOICE_STATUS_PARTIALLY_PAID:
      return 'Dalinai apmokėta';
    default:
      return '';
  }
};

export const mapInvoicePaymentStatusColor = status => {
  switch (status) {
    case INVOICE_STATUS_NOT_PAID:
      return 'var(--color__status-orange)';
    case INVOICE_STATUS_PAID:
      return 'var(--color__status-green)';
    case INVOICE_STATUS_PARTIALLY_PAID:
      return 'var(--color__status-yellow)';
    default:
      return '';
  }
};

export const mapAutomaticWashCodeStatuses = status => {
  switch (status) {
    case 1601:
      return 'Apmokėtas';
    case 1602:
      return 'Panaudotas';
    case 1603:
      return 'Galiojiams baigėsi';
    default:
      return '-';
  }
};

export const mapTextileCodeColor = status => {
  switch (status) {
    case 1601:
      return 'var(--color__status-green)';
    default:
      return 'var(--color__status-red)';
  }
};

export const mapCouponType = type => {
  switch (type) {
    case 1501:
      return 'Virtuali pinginė';
    case 1502:
      return 'Nuolaida';
    default:
      return '-';
  }
};

export const mapBenefitResetPeriods = type => {
  switch (type) {
    case 51:
      return 'Kasdien';
    case 52:
      return 'Kas savaitę';
    case 53:
      return 'Kas mėnesį';
    default:
      return '';
  }
};

export const mapDiscountPeriods = type => {
  switch (type) {
    case 561:
      return 'Mėnesinė';
    case 562:
      return 'Kasmet';
    default:
      return '';
  }
};

export const mapCampaignStatus = type => {
  switch (type) {
    case CAMPAIGN_STATUS_DRAFT:
      return CAMPAIGN_STATUS_DRAFT_NAME;
    case CAMPAIGN_STATUS_ACTIVE:
      return CAMPAIGN_STATUS_ACTIVE_NAME;
    case CAMPAIGN_STATUS_SENT:
      return CAMPAIGN_STATUS_SENT_NAME;
    case CAMPAIGN_STATUS_ERROR:
      return CAMPAIGN_STATUS_ERROR_NAME;
    default:
      return '';
  }
};

export const mapCampaignStatusColor = status => {
  switch (status) {
    case CAMPAIGN_STATUS_DRAFT:
      return 'var(--color__status-dark-grey)';
    case CAMPAIGN_STATUS_ACTIVE:
      return 'var(--color__status-green)';
    case CAMPAIGN_STATUS_SENT:
      return 'var(--color__status-green)';
    case CAMPAIGN_STATUS_ERROR:
      return 'var(--color__status-red)';
    default:
      return 'var(--color__status-dark-grey)';
  }
};

export const mapCampaignType = type => {
  switch (type) {
    case CAMPAIGN_TYPE_SMS:
      return 'SMS';
    case CAMPAIGN_TYPE_PUSH:
      return 'Push';
    case CAMPAIGN_TYPE_SMS_PUSH:
      return 'SMS/Push';
    default:
      return '';
  }
};

export const mapCampaignTypeColor = status => {
  switch (status) {
    case CAMPAIGN_TYPE_SMS:
      return 'var(--color__status-dark-grey)';
    case CAMPAIGN_TYPE_PUSH:
      return 'var(--color__status-dark-grey)';
    case CAMPAIGN_TYPE_SMS_PUSH:
      return 'var(--color__status-dark-grey)';
    default:
      return 'var(--color__status-dark-grey)';
  }
};
