import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './sidebar.module.scss';
import { config } from '../../../api/config';
import {
  hasPermission,
  chooseSidebarItems,
  isBusinessAdmin,
} from '../../../utils';
import { Icon } from '../../../components';
import { actions } from '../../../state';

const Sidebar = ({ dispatch, permissions, role, mounting }) => {
  const [menuItems, setMenuItems] = useState([]);

  const linksAdmin = [
    {
      name: config.PAGES.locations,
      icon: 'nav-locations',
      url: config.APP_PATHS.locations,
      permission: config.PERMISSIONS.locations.view,
    },
    {
      name: config.PAGES.orders,
      icon: 'nav-orders',
      url: config.APP_PATHS.orders,
      permission: config.PERMISSIONS.orders.view,
    },
    {
      name: config.PAGES.clients,
      icon: 'nav-clients',
      url: config.APP_PATHS.clients,
      permission: config.PERMISSIONS.clients.view,
    },
    {
      name: config.PAGES.businessClients,
      icon: 'nav-clients',
      url: config.APP_PATHS.businessClients,
      permission: config.PERMISSIONS.businessClients.view,
    },
    {
      name: config.PAGES.receipts,
      icon: 'nav-invoices',
      url: config.APP_PATHS.receipts,
      permission: config.PERMISSIONS.invoices.view,
    },
    {
      name: config.PAGES.invoices,
      icon: 'nav-invoices',
      url: config.APP_PATHS.invoices,
      permission: config.PERMISSIONS.invoices.view,
    },
    {
      name: config.PAGES.membershipOrders,
      icon: 'nav-membership-orders',
      url: config.APP_PATHS.membershipOrders,
      permission: config.PERMISSIONS.membershipOrders.view,
    },
    {
      name: config.PAGES.memberships,
      icon: 'nav-memberships',
      url: config.APP_PATHS.memberships,
      permission: config.PERMISSIONS.memberships.view,
    },
    {
      name: config.PAGES.discounts,
      icon: 'nav-discounts',
      url: config.APP_PATHS.discounts,
      permission: config.PERMISSIONS.discounts.view,
    },
    {
      name: config.PAGES.textile,
      icon: 'nav-locations',
      url: config.APP_PATHS.textile,
      permission: config.PERMISSIONS.textile.view,
    },
    {
      name: config.PAGES.coupons,
      icon: 'nav-locations',
      url: config.APP_PATHS.coupons,
      permission: config.PERMISSIONS.coupons.view,
    },
    {
      name: config.PAGES.campaignNotifications,
      icon: 'nav-discounts',
      url: config.APP_PATHS.campaignNotifications,
      permission: config.PERMISSIONS.discounts.view,
    },
  ];

  const linksBusinessAdmin = [
    {
      name: config.PAGES.baEmployees,
      icon: 'nav-clients',
      url: config.APP_PATHS.baEmployees,
    },
    {
      name: config.PAGES.baHistory,
      icon: 'nav-orders',
      url: config.APP_PATHS.baOrderHistory,
    },
    {
      name: config.PAGES.baInvoices,
      icon: 'nav-invoices',
      url: config.APP_PATHS.baInvoices,
    },
    {
      name: config.PAGES.baDiscountPlans,
      icon: 'nav-invoices',
      url: config.APP_PATHS.baDiscountPlans,
    },
  ];

  useEffect(() => {
    const items = chooseSidebarItems(role, linksAdmin, linksBusinessAdmin);
    setMenuItems(items);
  }, [role]);

  // Business admins do not use permissions, hence when mapping
  // not needed to check
  const renderLinks = () =>
    menuItems.map(({ name, icon, url, permission }) =>
      isBusinessAdmin(role) ? (
        <li className={styles.item} key={name}>
          <NavLink
            className={styles.link}
            activeClassName={styles.active}
            to={url}
          >
            <Icon name={icon} classes={{ icon: styles.icon }} />
            <span className={styles.label}>{name}</span>
          </NavLink>
        </li>
      ) : (
        hasPermission(permission, permissions) && (
          <li className={styles.item} key={name}>
            <NavLink
              className={styles.link}
              activeClassName={styles.active}
              to={url}
            >
              <Icon name={icon} classes={{ icon: styles.icon }} />
              <span className={styles.label}>{name}</span>
            </NavLink>
          </li>
        )
      )
    );

  return (
    <aside className={styles.sidebar}>
      <nav className={styles.nav}>
        <ul className={styles.list}>{renderLinks()}</ul>
      </nav>
    </aside>
  );
};

const mapStateToProps = state => ({
  permissions: state.auth.permissions,
  role: state.auth.user.role,
  mounting: state.auth.mounting,
});

Sidebar.propTypes = {
  dispatch: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.number).isRequired,
  role: PropTypes.number,
  mounting: PropTypes.bool,
};

export default connect(mapStateToProps)(Sidebar);
