import React from 'react';
import styles from '../segments.module.scss';
import MultiselectAll from '../../form/multiselectAll/multiselectAll';
import { mapServiceTypes, serviceTypes } from '../../../utils';
import Select from '../../form/select/select';
import LabelElement from '../../form/_elements/labelElement/labelElement';

const ServiceSegment = ({ segmentId, condition, onChange, frequencyTypes }) => {
  const services = condition?.values;

  return (
    <div className={styles.segmentForm}>
      <div className={styles.inputsRow}>
        <div className={styles.selectWrapper}>
          <MultiselectAll
            array={serviceTypes}
            type="array"
            renderName={mapServiceTypes}
            valueType="number"
            allText="Visos paslaugos"
            customId={segmentId}
            state={serviceTypes.length === services.length ? [] : services}
            setState={value => {
              onChange({ name: 'values', value });
            }}
          />
        </div>
        <div className={styles.frequencyTypeSelect}>
          <div className={styles.labelContainer}>
            <LabelElement
              label="Panaudojimo kartų per laikotarpį klientui"
              htmlFor={`${segmentId}-${'frequency'}`}
            />
          </div>
          <Select
            name={`${segmentId}-${'frequency'}`}
            options={frequencyTypes}
            selectedValue={condition.frequency}
            onChange={e =>
              onChange({ name: 'frequency', value: e.target.value })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceSegment;
