// campaign notification statuses
export const CAMPAIGN_STATUS_DRAFT = 1801;
export const CAMPAIGN_STATUS_DRAFT_NAME = 'Juodraštis';
export const CAMPAIGN_STATUS_ACTIVE = 1802;
export const CAMPAIGN_STATUS_ACTIVE_NAME = 'Aktyvus';
export const CAMPAIGN_STATUS_SENT = 1803;
export const CAMPAIGN_STATUS_SENT_NAME = 'Išsiųstas';
export const CAMPAIGN_STATUS_ERROR = 1804;
export const CAMPAIGN_STATUS_ERROR_NAME = 'Klaida';

// campaign notification types
export const CAMPAIGN_TYPE_SMS = 1851;
export const CAMPAIGN_TYPE_SMS_NAME = 'SMS';
export const CAMPAIGN_TYPE_PUSH = 1852;
export const CAMPAIGN_TYPE_PUSH_NAME = 'Push';
export const CAMPAIGN_TYPE_SMS_PUSH = 1853;
export const CAMPAIGN_TYPE_SMS_PUSH_NAME = 'SMS/Push';

// campaign notification condition types
export const CONDITION_TYPE_MEMBERSHIP = 1901;
export const CONDITION_TYPE_MEMBERSHIP_NAME = 'Nuolaidų planų turėjimą';

export const CONDITION_TYPE_BUSINESS_STATUS = 1902;
export const CONDITION_TYPE_BUSINESS_STATUS_NAME = 'Kliento tipą';

export const CONDITION_TYPE_SERVICE = 1903;
export const CONDITION_TYPE_SERVICE_NAME = 'Paslaugų panaudojimą';

export const CONDITION_TYPE_LOCATION = 1904;
export const CONDITION_TYPE_LOCATION_NAME = 'Lokaciją';

export const CONDITION_TYPE_MONEY_SPENT = 1905;
export const CONDITION_TYPE_MONEY_SPENT_NAME = 'Užsakymo sumą';

export const BUSINESS_STATUS_WITH_CONTRACT = 1951;
export const BUSINESS_STATUS_WITHOUT_CONTRACT = 1952;
export const BUSINESS_STATUS_PRIVATE = 1953;

export const membershipConditionSegment = {
  type: CONDITION_TYPE_MEMBERSHIP,
  values: [],
};

export const businessStatusConditionSegment = {
  type: CONDITION_TYPE_BUSINESS_STATUS,
  values: [],
};

export const serviceConditionSegment = {
  type: CONDITION_TYPE_SERVICE,
  values: [],
  frequency: 1,
};

export const locationConditionSegment = {
  type: CONDITION_TYPE_LOCATION,
  values: [],
  frequency: 1,
};

export const moneySpentConditionSegment = {
  type: CONDITION_TYPE_MONEY_SPENT,
  from_value: 0,
  to_value: 0,
};
