import React from 'react';
import styles from '../segments.module.scss';
import MultiselectAll from '../../form/multiselectAll/multiselectAll';
import LabelElement from '../../form/_elements/labelElement/labelElement';
import Select from '../../form/select/select';

const LocationSegment = ({
  segmentId,
  condition,
  onChange,
  availableLocations,
  frequencyTypes,
}) => {
  const locations = condition?.values;

  return (
    <div className={styles.segmentForm}>
      <div className={styles.inputsRow}>
        <div className={styles.selectWrapper}>
          <MultiselectAll
            array={availableLocations}
            type="object"
            name="full_address"
            value="id"
            valueType="number"
            allText="Visos lokacijos"
            customId={segmentId}
            state={locations}
            setState={value => {
              onChange({ name: 'values', value });
            }}
          />
        </div>
        <div className={styles.frequencyTypeSelect}>
          <div className={styles.labelContainer}>
            <LabelElement
              label="Dažnumas"
              htmlFor={`${segmentId}-${'frequency'}`}
            />
          </div>
          <Select
            name={`${segmentId}-${'frequency'}`}
            options={frequencyTypes}
            selectedValue={condition.frequency}
            onChange={e =>
              onChange({ name: 'frequency', value: e.target.value })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default LocationSegment;
