import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../checkbox/checkbox';

const MultiselectAll = ({
  type,
  array,
  name,
  renderName,
  value,
  valueType,
  state,
  setState,
  allText,
  customId,
}) => {
  const isChecked = value => state.includes(value);

  const handleCheckAll = ({ target: { checked } }) => {
    return checked && setState([]);
  };

  const handleCheckboxChange = ({ target: { value, checked } }) => {
    let newState;
    switch (valueType) {
      case 'number':
        newState = checked
          ? [...state, +value]
          : state.filter(v => v !== +value);
        break;
      case 'string':
        newState = checked ? [...state, value] : state.filter(v => v !== value);
        break;
      case 'boolean':
        newState = checked
          ? [...state, JSON.parse(value)]
          : state.filter(v => v !== JSON.parse(value));
        break;
      default:
        newState = [...state];
    }

    return setState([...newState]);
  };

  return (
    <>
      <Checkbox
        label={allText}
        name={allText}
        value={allText}
        onChange={e => handleCheckAll(e)}
        checked={!state.length || state.length === array.length}
        customId={customId}
      />
      {array.map(el => {
        switch (type) {
          case 'array':
            return (
              <Checkbox
                label={renderName ? renderName(el) : el}
                name={renderName ? renderName(el) : el}
                key={el}
                value={el}
                onChange={e => handleCheckboxChange(e)}
                checked={isChecked(el)}
                customId={customId}
              />
            );
          case 'object':
            return (
              <Checkbox
                label={el[name]}
                name={el[name]}
                key={el[value]}
                value={el[value]}
                onChange={e => handleCheckboxChange(e)}
                checked={isChecked(el[value])}
                customId={customId}
              />
            );
          default:
            return null;
        }
      })}
    </>
  );
};

MultiselectAll.defaultProps = {
  renderName: undefined,
  name: 'name',
  value: 'value',
};

MultiselectAll.propTypes = {
  type: PropTypes.string.isRequired,
  array: PropTypes.array.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  renderName: PropTypes.func,
  valueType: PropTypes.oneOf(['string', 'number', 'boolean']).isRequired,
  state: PropTypes.array,
  setState: PropTypes.func.isRequired,
};

export default MultiselectAll;
