import { takeLatest, all, call } from 'redux-saga/effects';
import { constants } from '.';

import * as saga from '.';

function* actionWatcher() {
  /* AUTH */
  yield takeLatest(constants.auth.LOGIN_USER, saga.loginUserSaga);
  yield takeLatest(constants.auth.MOUNT_USER, saga.mountUserSaga);
  yield takeLatest(
    constants.auth.MOUNT_ON_IMPERSONATION,
    saga.mountUserOnImpersonationSaga
  );
  yield takeLatest(constants.auth.END_IMPERSONATION, saga.endImpersonationSaga);
  yield takeLatest(constants.auth.CLEAR_USER, saga.clearUserSaga);
  yield takeLatest(constants.auth.UPDATE_PASS, saga.updatePassSaga);
  yield takeLatest(
    constants.auth.RESET_PASS_REQUEST,
    saga.resetPassRequestSaga
  );
  yield takeLatest(
    constants.auth.RESET_PASS_FROM_TOKEN,
    saga.resetPassFromTokenSaga
  );
  yield takeLatest(
    constants.auth.IMPERSONATE_BUSINESS_CLIENT,
    saga.impersonateBusinessClientSaga
  );
  yield takeLatest(constants.auth.VERIFY_2FA_CODE, saga.verify2faCodeSaga);
  yield takeLatest(constants.auth.RESEND_2FA_CODE, saga.resend2faCode);

  /* SEARCH */
  yield takeLatest(constants.search.GET_SEARCH, saga.getClientsSearchSaga);
  yield takeLatest(
    constants.search.GET_H2_CARD_SEARCH,
    saga.getH2CardsSearchSaga
  );

  /* ERROR */
  yield takeLatest(constants.error.SET_ALERT, saga.setAlertSaga);

  /* ADMINS */
  yield takeLatest(constants.admins.GET_ADMINS, saga.getAdminsSaga);
  yield takeLatest(constants.admins.GET_ADMIN, saga.getAdminSaga);
  yield takeLatest(constants.admins.ADD_ADMIN, saga.addAdminSaga);
  yield takeLatest(constants.admins.EDIT_ADMIN, saga.editAdminSaga);
  yield takeLatest(
    constants.admins.DEACTIVATE_ADMIN,
    saga.deactivateAdminAction
  );

  /* LOCATIONS */
  yield takeLatest(constants.locations.GET_LOCATIONS, saga.getLocationsSaga);
  yield takeLatest(
    constants.locations.GET_SELECTED_LOCATION,
    saga.getSelectedLocationSaga
  );
  yield takeLatest(
    constants.locations.EXPORT_LOCATIONS,
    saga.exportLocationsSaga
  );
  yield takeLatest(constants.locations.ADD_LOCATIONS, saga.addLocationsSaga);
  yield takeLatest(
    constants.locations.DOWNLOAD_LOCATIONS_QR,
    saga.downloadQrSaga
  );

  /* CLIENTS */
  yield takeLatest(constants.clients.GET_CLIENTS, saga.getClientsSaga);
  yield takeLatest(constants.clients.GET_CLIENT, saga.getClientSaga);
  yield takeLatest(constants.clients.UPDATE_CLIENT, saga.updateClientSaga);
  yield takeLatest(constants.clients.GET_CLIENT_CARDS, saga.getClientCardsSaga);
  yield takeLatest(
    constants.clients.DEACTIVATE_CLIENTS_MEMBERSHIP_ORDER,
    saga.deactivateClientsMembershipOrderSaga
  );
  yield takeLatest(constants.clients.EXPORT_CLIENTS, saga.exportClientsSaga);
  yield takeLatest(
    constants.clients.EXPORT_CLIENTS_ASYNC,
    saga.exportClientsAsyncSaga
  );
  yield takeLatest(
    constants.clients.DELETE_CLIENT_DATA,
    saga.deleteClientDataSaga
  );
  yield takeLatest(constants.clients.TOP_UP_WALLET, saga.topUpWalletSaga);

  /* BUSINESS CLIENTS */
  yield takeLatest(
    constants.businessClients.GET_CLIENTS,
    saga.getBusinessClientsSaga
  );
  yield takeLatest(
    constants.businessClients.GET_CLIENT,
    saga.getBusinessClientSaga
  );
  yield takeLatest(
    constants.businessClients.UPDATE_CLIENT,
    saga.updateBusinessClientSaga
  );
  yield takeLatest(
    constants.businessClients.ADD_BUSINESS_CLIENT,
    saga.addBusinessClientSaga
  );
  yield takeLatest(
    constants.businessClients.UPDATE_STATUS,
    saga.updateBusinessClientStatusSaga
  );
  yield takeLatest(
    constants.businessClients.UPLOAD_CONTRACT,
    saga.uploadContractSaga
  );
  yield takeLatest(
    constants.businessClients.UPLOAD_LOGOTYPE,
    saga.uploadLogotypeSaga
  );
  yield takeLatest(
    constants.businessClients.GET_CLIENTS_MEMBERSHIPS,
    saga.getBusinessClientsMembershipsSaga
  );
  yield takeLatest(
    constants.businessClients.ADD_CLIENTS_MEMBERSHIP,
    saga.addBusinessClientsMembershipSaga
  );
  yield takeLatest(
    constants.businessClients.UPDATE_CLIENTS_MEMBERSHIP,
    saga.updateBusinessClientsMembershipSaga
  );
  yield takeLatest(
    constants.businessClients.GET_MANAGER_ADMINS,
    saga.getManagerAdminsSaga
  );
  yield takeLatest(
    constants.businessClients.SEARCH_COMPANY_DETAILS,
    saga.searchCompanyDetailsSaga
  );
  yield takeLatest(
    constants.businessClients.SEARCH_COMPANY_DETAILS_BY_CODE,
    saga.searchCompanyDetailsByCodeSaga
  );
  yield takeLatest(
    constants.businessClients.GET_SINGLE_COMPANY_DETAILS_ACTION,
    saga.getSingleCompanyDetailsSaga
  );
  yield takeLatest(
    constants.businessClients.EXPORT_BUSINESS_CLIENTS,
    saga.exportBusinessClientsSaga
  );

  /* DISCOUNTS */
  yield takeLatest(constants.discounts.GET_DISCOUNTS, saga.getDiscountsSaga);
  yield takeLatest(constants.discounts.ADD_DISCOUNT, saga.addDiscountSaga);
  yield takeLatest(constants.discounts.GET_DISCOUNT, saga.getDiscountSaga);
  yield takeLatest(constants.discounts.EDIT_DISCOUNT, saga.editDiscountSaga);
  yield takeLatest(
    constants.discounts.DEACTIVATE_DISCOUNT,
    saga.deactivateDiscountSaga
  );
  yield takeLatest(
    constants.discounts.EXPORT_DISCOUNTS,
    saga.exportDiscountsSaga
  );
  yield takeLatest(
    constants.discounts.UPLOAD_IMAGE,
    saga.uploadDiscountBannerSaga
  );

  /* ORDERS */
  yield takeLatest(constants.orders.GET_ORDERS, saga.getOrdersSaga);
  yield takeLatest(constants.orders.GET_ORDER, saga.getOrderSaga);
  yield takeLatest(constants.orders.EXPORT_ORDERS, saga.exportOrdersSaga);
  yield takeLatest(
    constants.orders.EXPORT_ORDERS_ASYNC,
    saga.exportOrdersAsyncSaga
  );
  yield takeLatest(constants.orders.RETRY_PAYMENT, saga.retryPaymentSaga);
  yield takeLatest(
    constants.orders.CHANGE_ORDER_STATUS_TO_PAID,
    saga.changeOrderStatusToPaid
  );

  /* INVOICES */
  yield takeLatest(constants.invoices.GET_INVOICES, saga.getInvoicesSaga);
  yield takeLatest(constants.invoices.GET_INVOICE, saga.getInvoiceSaga);
  yield takeLatest(constants.invoices.EXPORT_INVOICES, saga.exportInvoicesSaga);
  yield takeLatest(
    constants.invoices.EXPORT_INVOICES_ASYNC,
    saga.exportInvoicesAsyncSaga
  );
  yield takeLatest(
    constants.invoices.EXPORT_INVOICES_HEADS,
    saga.exportInvoicesHeadsSaga
  );
  yield takeLatest(
    constants.invoices.EXPORT_INVOICES_BODIES,
    saga.exportInvoicesBodiesSaga
  );
  yield takeLatest(
    constants.invoices.EXPORT_INVOICES_PARTNERS,
    saga.exportInvoicesPartnersSaga
  );
  yield takeLatest(
    constants.invoices.EXPORT_INVOICES_XML,
    saga.exportInvoicesXmlSaga
  );
  yield takeLatest(
    constants.invoices.GENERATE_MONTHLY_INVOICES,
    saga.generateMonthlyInvoicesSaga
  );
  yield takeLatest(
    constants.invoices.UPDATE_PAYMENT_STATUS,
    saga.updateInvoicePaymentStatus
  );
  yield takeLatest(constants.invoices.DOWNLOAD_PDF, saga.downloadInvoicePdf);
  yield takeLatest(constants.invoices.UPDATE_INVOICE, saga.updateInvoiceSaga);

  /* RECEIPTS */
  yield takeLatest(constants.receipts.GET_RECEIPTS, saga.getReceiptsSaga);
  yield takeLatest(constants.receipts.GET_RECEIPT, saga.getReceiptSaga);
  yield takeLatest(constants.receipts.EXPORT_RECEIPTS, saga.exportReceiptsSaga);
  yield takeLatest(
    constants.receipts.EXPORT_RECEIPTS_ASYNC,
    saga.exportReceiptsAsyncSaga
  );
  yield takeLatest(
    constants.receipts.EXPORT_RECEIPTS_XML,
    saga.exportReceiptsXmlSaga
  );
  yield takeLatest(
    constants.receipts.DOWNLOAD_PDF,
    saga.downloadReceiptPdfSaga
  );

  /* MEMBERSHIPS */

  yield takeLatest(
    constants.memberships.GET_MEMBERSHIPS,
    saga.getMembershipsSaga
  );
  yield takeLatest(
    constants.memberships.GET_MEMBERSHIP,
    saga.getMembershipSaga
  );
  yield takeLatest(
    constants.memberships.EDIT_MEMBERSHIP,
    saga.editMembershipSaga
  );
  yield takeLatest(
    constants.memberships.CREATE_MEMBERSHIP,
    saga.createMembershipSaga
  );
  yield takeLatest(
    constants.memberships.DEACTIVATE_MEMBERSHIP,
    saga.deactivateMembershipSaga
  );
  yield takeLatest(
    constants.memberships.ADD_MEMBERSHIP_HISTORY,
    saga.addMembershipHistorySaga
  );
  yield takeLatest(
    constants.memberships.EDIT_MEMBERSHIP_HISTORY,
    saga.editMembershipHistorySaga
  );
  yield takeLatest(
    constants.memberships.DELETE_MEMBERSHIP_HISTORY,
    saga.deleteMembershipHistorySaga
  );
  yield takeLatest(
    constants.memberships.EXPORT_MEMBERSHIPS,
    saga.exportMembershipsSaga
  );
  yield takeLatest(
    constants.memberships.ADD_MEMBERSHIP_ORDER_TO_CLIENT,
    saga.addMembershipOrderToClientSaga
  );

  /* MEMBERSHIP ORDERS */
  yield takeLatest(
    constants.membershipOrders.GET_MEMBERSHIP_ORDERS,
    saga.getMembershipOrdersSaga
  );
  yield takeLatest(
    constants.membershipOrders.GET_MEMBERSHIP_ORDER,
    saga.getMembershipOrderSaga
  );
  yield takeLatest(
    constants.membershipOrders.DEACTIVATE_MEMBERSHIP_ORDER,
    saga.deactivateMembershipOrderSaga
  );
  yield takeLatest(
    constants.membershipOrders.EXPORT_MEMBERSHIP_ORDERS,
    saga.exportMembershipOrdersSaga
  );

  /* TEXTILE */
  yield takeLatest(
    constants.textile.GET_AUTOMATIC_WASH_CODES,
    saga.getAutomaticWashCodesTableSaga
  );

  yield takeLatest(constants.textile.USE_WASH_CODE, saga.useWashCodeSaga);

  yield takeLatest(constants.textile.REMOVE_WASH_CODE, saga.removeWashCodeSaga);

  /* BOXES */
  yield takeLatest(
    constants.boxes.GET_BOXES_TABLE_DATA,
    saga.getBoxesTableDataSaga
  );

  /* SERVICES */
  yield takeLatest(
    constants.services.GET_SERVICES,
    saga.getservicesTableDataSaga
  );

  /* ADD EDIT SERVICES */
  yield takeLatest(
    constants.addEditServices.HANDLE_LOCATION_STATUS_CHANGE,
    saga.handleLocationStatusChangeSaga
  );
  yield takeLatest(
    constants.addEditServices.REGENERATE_DEVICE_KEY,
    saga.regenerateDeviceKey
  );

  /* CAMPAIGNS */
  yield takeLatest(constants.campaigns.GET_CAMPAIGNS, saga.getCampaignsSaga);
  yield takeLatest(constants.campaigns.ADD_CAMPAIGN, saga.addCampaignSaga);
  yield takeLatest(constants.campaigns.GET_CAMPAIGN, saga.getCampaignSaga);
  yield takeLatest(constants.campaigns.EDIT_CAMPAIGN, saga.editCampaignSaga);
  yield takeLatest(
    constants.campaigns.GET_CAMPAIGN_POTENTIAL_AUDIENCE,
    saga.getCampaignPotentialAudienceSaga
  );

  /* BA: EMPLOYEES */
  yield takeLatest(constants.employees.GET_EMPLOYEES, saga.getEmployeesSaga);
  yield takeLatest(constants.employees.GET_EMPLOYEE, saga.getEmployeeSaga);
  yield takeLatest(constants.employees.ADD_EMPLOYEE, saga.addEmployeeSaga);
  yield takeLatest(constants.employees.ADD_H2_CARD, saga.addH2CardSaga);
  yield takeLatest(constants.employees.GET_H2_CARD, saga.getH2CardSaga);
  yield takeLatest(
    constants.employees.UPDATE_EMPLOYEE,
    saga.updateEmployeeSaga
  );
  yield takeLatest(constants.employees.UPDATE_H2_CARD, saga.updateH2CardSaga);
  yield takeLatest(
    constants.employees.CHANGE_STATUS,
    saga.changeEmployeeStatusSaga
  );
  yield takeLatest(
    constants.employees.CHANGE_H2_CARD_STATUS,
    saga.changeH2CardStatusSaga
  );
  yield takeLatest(
    constants.employees.BULK_MEMBERSHIP_ASSIGN,
    saga.bulkMembershipAssignSaga
  );
  yield takeLatest(
    constants.employees.EXPORT_EMPLOYEES,
    saga.exportEmployeesSaga
  );

  /* BA: ORDER HISTORY*/
  yield takeLatest(
    constants.orderHistory.GET_ORDER_HISTORY,
    saga.getOrderHistorySaga
  );
  yield takeLatest(
    constants.orderHistory.GET_ORDER_HISTORY_RECORD,
    saga.getHistoricalOrderSaga
  );
  yield takeLatest(
    constants.orderHistory.EXPORT_ORDER_HISTORY,
    saga.exportOrderHistorySaga
  );
  yield takeLatest(
    constants.orderHistory.EXPORT_H2_CARD_ASYNC,
    saga.exportH2CardAsyncSaga
  );

  /* BA: INVOICES*/
  yield takeLatest(
    constants.businessInvoices.GET_INVOICES,
    saga.getBusinessInvoicesSaga
  );
  yield takeLatest(
    constants.businessInvoices.GET_INVOICE,
    saga.getBusinessInvoiceSaga
  );
  yield takeLatest(
    constants.businessInvoices.DOWNLOAD_PDF,
    saga.downloadBusinessInvoicePdfSaga
  );

  /* COUPONS */
  yield takeLatest(constants.coupons.GET_COUPONS, saga.getCouponsSaga);
  yield takeLatest(constants.coupons.GET_COUPON, saga.getCouponSaga);
  yield takeLatest(constants.coupons.EXPORT_COUPONS, saga.exportCouponsSaga);
  yield takeLatest(
    constants.coupons.EXPORT_COUPONS_CODE_BY_CODE,
    saga.exportCouponsCodeByCodeSaga
  );

  /* DOWNLOADS */
  yield takeLatest(
    constants.download.FETCH_EXPORTED_FILE,
    saga.downloadExportedFileSaga
  );

  /* COUNTRIES */
  yield takeLatest(constants.dropdowns.GET_COUNTRIES, saga.getCountriesSaga);
}

export default function* rootSaga() {
  yield all([call(actionWatcher)]);
}
