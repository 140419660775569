import { call, put } from 'redux-saga/effects';
import { config } from '../../api/config';
import { Api } from '../../api/api';
import { actions } from '..';
import { errorMessage } from '../../utils';

export function* getCampaignsSaga({ body: initialBody }) {
  try {
    yield put(actions.campaigns.setCampaignsLoadingAction(true));

    const body = {
      ...initialBody,
      limit: config.DEFAULTS.recordsPerPage,
    };

    const { campaigns, count, header } = yield call(Api.getCampaigns, body);

    yield put(actions.campaigns.setCampaignsAction(campaigns, count, header));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.campaigns.setCampaignsLoadingAction(false));
  }
}

export function* getCampaignSaga({ id }) {
  try {
    yield put(actions.campaigns.setCampaignsLoadingAction(true));

    const campaign = yield call(Api.getCampaign, id);

    yield put(actions.campaigns.setCampaignAction(campaign));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.campaigns.setCampaignsLoadingAction(false));
  }
}

export function* addCampaignSaga({ body, history }) {
  try {
    yield put(actions.campaigns.setCampaignsLoadingAction(true));

    yield call(Api.addCampaign, body);

    history.push(config.APP_PATHS.campaignNotifications);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.campaigns.setCampaignsLoadingAction(false));
  }
}

export function* editCampaignSaga({ id, body, history }) {
  try {
    yield put(actions.campaigns.setCampaignsLoadingAction(true));

    yield call(Api.editCampaign, id, body);

    history.push(config.APP_PATHS.campaignNotifications);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.discounts.setDiscountsLoadingAction(false));
  }
}

export function* getCampaignPotentialAudienceSaga({ body }) {
  try {
    yield put(
      actions.campaigns.setCampaignPotentialAudienceLoadingAction(true)
    );

    const result = yield call(Api.getCampaignPotentialAudience, body);
    yield put(actions.campaigns.setCampaignPotentialAudience(result));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(
      actions.campaigns.setCampaignPotentialAudienceLoadingAction(false)
    );
  }
}

// export function* exportCampaignsSaga({ filename }) {
//   try {
//     yield put(actions.download.setXlsLoadingAction(true));
//     const response = yield call(Api.exportDiscounts);

//     downloadFile(response, filename);
//   } catch (e) {
//     const msg = errorMessage(e);
//     yield put(actions.error.setErrorAction(msg));
//   } finally {
//     yield put(actions.download.setXlsLoadingAction(false));
//   }
// }
