import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { config } from '../../api/config';
import { actions } from '../../state';
import { Header, Breadcrumb } from '../../components';
import Form from './campaignNotifications.form';
import {
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_STATUS_DRAFT,
  CAMPAIGN_STATUS_SENT,
  CAMPAIGN_TYPE_PUSH,
  CAMPAIGN_TYPE_PUSH_NAME,
  CAMPAIGN_TYPE_SMS,
  CAMPAIGN_TYPE_SMS_NAME,
  CAMPAIGN_TYPE_SMS_PUSH,
  CAMPAIGN_TYPE_SMS_PUSH_NAME,
} from './constants';
import { validationErrors } from './campaignNotifications.validation';
import { formatLocaleDate, isEmpty } from '../../utils';
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns';
import { CampaignNotificationsContext } from './campaignNotifications.context';

const EditCampaignNotification = ({
  dispatch,
  history,
  match: { params },
  campaigns: {
    selectedCampaign,
    loading,
    potentialAudienceResult,
    potentialAudienceLoading,
  },
}) => {
  const lastMonth = subMonths(Date.now(), 1);

  const [values, setValues] = useState({
    type: CAMPAIGN_TYPE_SMS,
    title: '',
    notification_title_lt: '',
    notification_title_en: '',
    notification_title_ru: '',
    notification_body_lt: '',
    notification_body_en: '',
    notification_body_ru: '',
    deep_link: '',
    send_at_date: '',
    send_at_time: '',
    conditionalAudience: false,
    condition_from_date: '',
    condition_from_time: '',
    condition_to_date: '',
    condition_to_time: '',
  });

  const [conditions, setConditions] = useState([]);
  const [displayPotentialAudience, setDisplayPotentialAudience] = useState(
    false
  );
  const [submitButton, setSubmitButton] = useState(null);

  const {
    prepareConditionsForDisplay,
    prepareConditionsForPayload,
  } = useContext(CampaignNotificationsContext);

  const disableEdit = useMemo(
    () =>
      selectedCampaign?.status === CAMPAIGN_STATUS_ACTIVE ||
      selectedCampaign?.status === CAMPAIGN_STATUS_SENT,
    [selectedCampaign]
  );

  const campaignNotificationsTypes = [
    {
      name: CAMPAIGN_TYPE_SMS_NAME,
      value: CAMPAIGN_TYPE_SMS,
    },
    {
      name: CAMPAIGN_TYPE_PUSH_NAME,
      value: CAMPAIGN_TYPE_PUSH,
    },
    {
      name: CAMPAIGN_TYPE_SMS_PUSH_NAME,
      value: CAMPAIGN_TYPE_SMS_PUSH,
    },
  ];

  const fetchCampaign = useCallback(
    id => {
      dispatch(actions.campaigns.getCampaignAction(id));
    },
    [dispatch]
  );

  const loadValues = useCallback(() => {
    if (!isEmpty(selectedCampaign)) {
      const {
        type,
        title,
        notification_title_lt,
        notification_title_en,
        notification_title_ru,
        notification_body_lt,
        notification_body_en,
        notification_body_ru,
        deep_link,
        send_at,
        condition_from_date,
        condition_to_date,
        conditions,
      } = selectedCampaign;

      const areConditionsPresent = !!conditions?.length;

      const sendAtDate = send_at ? format(send_at, 'yyyy-MM-dd') : null;
      const sendAtTime = send_at
        ? new Date(send_at).toLocaleTimeString(config.DEFAULTS.locale, {
            hour: '2-digit',
            minute: '2-digit',
          })
        : null;

      const conditionFromDate = condition_from_date
        ? format(condition_from_date, 'yyyy-MM-dd')
        : format(startOfMonth(lastMonth), 'yyyy-MM-dd');
      const conditionFromTime = condition_from_date
        ? new Date(condition_from_date).toLocaleTimeString(
            config.DEFAULTS.locale,
            { hour: '2-digit', minute: '2-digit' }
          )
        : '00:00';

      const conditionToDate = condition_to_date
        ? format(condition_to_date, 'yyyy-MM-dd')
        : format(endOfMonth(lastMonth), 'yyyy-MM-dd');
      const conditionToTime = condition_to_date
        ? new Date(condition_to_date).toLocaleTimeString(
            config.DEFAULTS.locale,
            { hour: '2-digit', minute: '2-digit' }
          )
        : '23:59';

      setValues({
        type,
        title,
        notification_title_lt,
        notification_title_en,
        notification_title_ru,
        notification_body_lt,
        notification_body_en,
        notification_body_ru,
        deep_link,
        send_at_date: sendAtDate,
        send_at_time: sendAtTime,
        ...(conditionFromDate && {
          condition_from_date: conditionFromDate,
          condition_from_time: conditionFromTime,
        }),
        ...(conditionToDate && {
          condition_to_date: conditionToDate,
          condition_to_time: conditionToTime,
        }),
        conditionalAudience: areConditionsPresent,
      });
      if (areConditionsPresent) {
        const preparedConditions = prepareConditionsForDisplay(conditions);
        setConditions(preparedConditions);
      }
    }
  }, [selectedCampaign]);

  const clearCampaigns = useCallback(() => {
    dispatch(actions.campaigns.clearCampaignsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchCampaign(params.id);
    return () => clearCampaigns();
  }, [fetchCampaign, clearCampaigns, params.id]);

  useEffect(() => {
    loadValues();
  }, [loadValues]);

  const handleInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value });
  };

  const fetchPotentialAudience = () => {
    const conditionFrom =
      values.condition_from_date && values.condition_from_time
        ? new Date(
            values.condition_from_date + ' ' + values.condition_from_time
          ).toISOString()
        : undefined;

    const conditionTo =
      values.condition_to_date && values.condition_to_time
        ? new Date(
            values.condition_to_date + ' ' + values.condition_to_time
          ).toISOString()
        : undefined;

    const preparedConditions = conditions?.length
      ? prepareConditionsForPayload(conditions)
      : [];

    const body = {
      type: parseInt(values.type),
      ...(values?.conditionalAudience && {
        condition_from_date: conditionFrom,
        condition_to_date: conditionTo,
        conditions: preparedConditions,
      }),
    };
    dispatch(actions.campaigns.getCampaignPotentialAudience(body));
  };

  const setConditionalAudience = e => {
    const newValue = e.currentTarget.value === 'true' ? false : true;
    setValues({
      ...values,
      conditionalAudience: newValue,
    });
  };

  const getCampaignTypeName = type => {
    switch (parseInt(type)) {
      case CAMPAIGN_TYPE_SMS:
        return CAMPAIGN_TYPE_SMS_NAME;
      case CAMPAIGN_TYPE_PUSH:
        return CAMPAIGN_TYPE_PUSH_NAME;
      case CAMPAIGN_TYPE_SMS_PUSH:
        return CAMPAIGN_TYPE_SMS_PUSH_NAME;
      default:
        return '';
    }
  };

  const openCampaignConfirmModal = (id, body, history) => {
    const sendAtDate = new Date(
      values.send_at_date + ' ' + values.send_at_time
    );

    const modalPayload = {
      modalName: 'confirmCampaignModal',
      title: '',
      type: getCampaignTypeName(values.type),
      date: formatLocaleDate(sendAtDate),
      onConfirm: () => {
        dispatch(actions.campaigns.editCampaignAction(id, body, history));
      },
      onFetchPotentialAudience: () => {
        fetchPotentialAudience();
      },
    };
    dispatch(actions.modals.setModalVisible(modalPayload));
  };

  const handleSubmit = e => {
    e.preventDefault();

    const error = validationErrors(values);

    if (error) {
      dispatch(actions.error.setErrorAction({ msg: error }));
    } else {
      const sendAtDateToSubmit =
        values.send_at_date && values.send_at_time
          ? new Date(
              values.send_at_date + ' ' + values.send_at_time
            ).toISOString()
          : null;

      const conditionFromToSubmit =
        values.conditionalAudience &&
        values.condition_from_date &&
        values.condition_from_time
          ? new Date(
              values.condition_from_date + ' ' + values.condition_from_time
            ).toISOString()
          : null;

      const conditionToToSubmit =
        values.conditionalAudience &&
        values.condition_to_date &&
        values.condition_to_time
          ? new Date(
              values.condition_to_date + ' ' + values.condition_to_time
            ).toISOString()
          : null;

      const preparedConditions =
        values.conditionalAudience && conditions?.length
          ? prepareConditionsForPayload(conditions)
          : [];

      const isNotificationTypeSMS =
        parseInt(values?.type) === CAMPAIGN_TYPE_SMS;

      const campaignStatus =
        submitButton === 'send'
          ? CAMPAIGN_STATUS_ACTIVE
          : CAMPAIGN_STATUS_DRAFT;

      const body = {
        type: parseInt(values.type),
        status: campaignStatus,
        title: values.title,
        notification_title_lt: values.notification_title_lt,
        notification_title_en: values.notification_title_en,
        notification_title_ru: values.notification_title_ru,
        notification_body_lt: values.notification_body_lt,
        notification_body_en: values.notification_body_en,
        notification_body_ru: values.notification_body_ru,
        send_at: sendAtDateToSubmit,
        ...(!isNotificationTypeSMS &&
          values?.deep_link && {
            deep_link: values.deep_link,
          }),
        ...(values?.conditionalAudience && {
          condition_from_date: conditionFromToSubmit,
          condition_to_date: conditionToToSubmit,
          conditions: preparedConditions,
        }),
      };

      if (submitButton === 'send') {
        openCampaignConfirmModal(params.id, body, history);
      } else {
        dispatch(
          actions.campaigns.editCampaignAction(params.id, body, history)
        );
      }
    }
  };

  return (
    <section>
      <Header size="sm">
        <Breadcrumb
          title={config.PAGES.campaignNotifications}
          subtitle={selectedCampaign.title}
          baseUrl={config.APP_PATHS.campaignNotifications}
        />
      </Header>
      <Form
        values={values}
        onChange={handleInputChange}
        onSubmit={handleSubmit}
        loading={loading}
        disableEdit={disableEdit}
        setConditionalAudience={setConditionalAudience}
        campaignNotificationsTypes={campaignNotificationsTypes}
        fetchPotentialAudience={fetchPotentialAudience}
        potentialAudienceResult={potentialAudienceResult}
        potentialAudienceLoading={potentialAudienceLoading}
        conditions={conditions}
        setConditions={setConditions}
        displayPotentialAudience={displayPotentialAudience}
        setDisplayPotentialAudience={setDisplayPotentialAudience}
        setSubmitButton={setSubmitButton}
      />
    </section>
  );
};

const mapStateToProps = state => ({
  campaigns: state.campaigns,
});

EditCampaignNotification.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  campaigns: PropTypes.shape({
    selectedCampaign: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.number,
      status: PropTypes.number,
      title: PropTypes.string,
      notification_title_lt: PropTypes.string,
      notification_title_en: PropTypes.string,
      notification_title_ru: PropTypes.string,
      notification_body_lt: PropTypes.string,
      notification_body_en: PropTypes.string,
      notification_body_ru: PropTypes.string,
      deep_link: PropTypes.string,
      created_at: PropTypes.number,
      send_at: PropTypes.number,
      condition_from_date: PropTypes.number,
      condition_to_date: PropTypes.number,
      conditions: PropTypes.array,
    }),
    loading: PropTypes.bool.isRequired,
    potentialAudienceResult: PropTypes.number,
    potentialAudienceLoading: PropTypes.bool,
  }).isRequired,
};

export default connect(mapStateToProps)(EditCampaignNotification);
