import { constants } from '..';

const INITIAL_STATE = {
  campaigns: [],
  selectedCampaign: {},
  loading: false,
  header: {},
  total: 0,
  potentialAudienceResult: null,
  potentialAudienceLoading: false,
};

export const campaignsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.campaigns.SET_CAMPAIGNS_LOADING:
      return {
        ...state,
        loading: action.flag,
      };
    case constants.campaigns.SET_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.campaigns,
        total: action.count,
        header: action.header,
      };
    case constants.campaigns.CLEAR_CAMPAIGNS:
      return {
        ...INITIAL_STATE,
      };
    case constants.campaigns.SET_CAMPAIGN:
      return {
        ...state,
        selectedCampaign: action.campaign,
      };
    case constants.campaigns.SET_CAMPAIGN_POTENTIAL_AUDIENCE:
      return {
        ...state,
        potentialAudienceResult: action.result.result,
      };
    case constants.campaigns.SET_CAMPAIGN_POTENTIAL_AUDIENCE_LOADING:
      return {
        ...state,
        potentialAudienceLoading: action.flag,
      };
    default:
      return state;
  }
};
