import React from 'react';
import styles from './segments.module.scss';
import TitleSecondary from '../titleSecondary/titleSecondary';

// type: "and" | "or";
const SegmentDivision = ({ type }) => {
  const title = type === 'and' ? 'IR' : type === 'or' ? 'ARBA' : '';
  return (
    <div
      className={`${styles.segmentDivision} ${
        type === 'or' ? styles.divisionTypeOr : ''
      }`}
    >
      <div className={styles.segmentDivisionTitle}>
        <TitleSecondary>{title}</TitleSecondary>
      </div>
    </div>
  );
};

export default SegmentDivision;
