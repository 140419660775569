import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './businessClients.module.scss';
import { actions } from '../../state';
import { config } from '../../api/config';
import { isEmpty } from '../../utils';
import BusinessClientFinanceInfo from './businessClients.financeInfo.component';
import {
  Header,
  Breadcrumb,
  Toggle,
  PaperNav,
  PaperContainer,
  ButtonLink,
  PaperNote,
} from '../../components';
import { businessClientsActions } from '../../state/businessClients/businessClients.actions';
import { validationErrors } from './businessClients.validations';
import { BusinessClientsContext } from './businessClients.context';
import { useContext } from 'react';
import EditMembership from './businessClientMembership.edit';
import Tabs from '../../components/tabs/tabs';
import GeneralInfoTabContent from './generalInfoTab.content';
import MembershipsTabContent from './membershipsTab.content';
import { useDebouncedCallback } from 'use-debounce';

const tabsContent = [
  {
    title: 'Bendra informacija',
    tabIdx: 0,
  },
  {
    title: 'Narystės',
    tabIdx: 1,
  },
];

const EditBusinessClient = ({
  dispatch,
  history,
  match: { params },
  businessClients: {
    loading,
    selectedClient,
    selectedClientMemberships,
    managerAdmins,
    companyDetails,
    companyDetailsByCode,
    companyDetailsLoading,
    companyDetailsError,
    companyDetailsByCodeError,
    singleCompanyDetails,
  },
  memberships,
  dropdowns: { countries },
}) => {
  const { activeTab, setActiveTab } = useContext(BusinessClientsContext);
  const [clientStatus, setClientStatus] = useState(false);
  const [values, setValues] = useState(selectedClient);
  const [sendPassword, setSendPassword] = useState(false);
  const [contractValidFrom, setContractValidFrom] = useState(null);
  const [contractValidTo, setContractValidTo] = useState(null);

  const fetchClient = useCallback(
    id => {
      dispatch(actions.businessClients.getBusinessClientAction(id));
    },
    [dispatch]
  );
  const fetchClientsMemberships = useCallback(
    id => {
      dispatch(
        actions.businessClients.getSelectedClientMembershipsAction(Number(id))
      );
    },
    [dispatch]
  );
  const fetchManagerAdmins = useCallback(() => {
    const body = {
      filter: {
        business_role: config.BUSINESS_ROLES.MANAGER.value,
      },
    };
    dispatch(actions.businessClients.getManagerAdminsAction(body));
  }, [dispatch]);

  const fetchCountries = useCallback(() => {
    dispatch(actions.dropdowns.getCountriesAction());
  }, [dispatch]);

  const clearClient = useCallback(() => {
    dispatch(actions.businessClients.clearBusinessClientsAction());
  }, [dispatch]);

  const loadValues = useCallback(() => {
    if (!isEmpty(selectedClient)) {
      const {
        status,
        name,
        email,
        invoice_emails,
        owner_full_name,
        phone,
        company_code,
        tax_code,
        address,
        credit_limit,
        discount,
        sendPassword,
        contract_start_date,
        contract_end_date,
        send_invite,
        payment_term,
        contract,
        logo,
        progressive_discount,
        color,
        manager_id,
        city,
        postal_code,
        country_code,
      } = selectedClient;

      setValues({
        status,
        name,
        email,
        invoice_emails: invoice_emails.join(','),
        owner_full_name,
        phone,
        company_code,
        tax_code: tax_code || '',
        address,
        credit_limit: credit_limit ? credit_limit / 100 : 0,
        discount: discount ? discount : 0,
        sendPassword: Boolean(send_invite || sendPassword),
        payment_term,
        contract_start_date,
        contract_end_date,
        contract,
        logo,
        progressive_discount,
        color,
        manager_id,
        city,
        postal_code,
        country_code,
      });
      setClientStatus(status === 1 ? true : false);
    }
  }, [selectedClient]);

  useEffect(() => {
    fetchClient(params.id);
    return () => clearClient();
  }, [fetchClient, clearClient, params.id]);

  useEffect(() => {
    if (activeTab === 1) {
      fetchClientsMemberships(params.id);
    }
  }, [fetchClientsMemberships, params.id, activeTab]);

  useEffect(() => {
    if (activeTab === 0) {
      fetchManagerAdmins();
    }
  }, [fetchManagerAdmins, activeTab]);

  useEffect(() => {
    if (activeTab === 0) {
      fetchCountries();
    }
  }, [fetchCountries, activeTab]);

  useEffect(() => {
    loadValues();
  }, [loadValues]);

  const fetchMemberships = useCallback(() => {
    dispatch(actions.memberships.getMembershipsAction());
  }, [dispatch]);

  const clearMemberships = useCallback(() => {
    dispatch(actions.memberships.clearMembershipsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchMemberships();
    return () => {
      clearMemberships();
      setActiveTab(0);
    };
  }, [fetchMemberships, clearMemberships]);

  const toggleStatus = () => {
    const newStatus = clientStatus ? 0 : 1;
    setClientStatus(clientStatus => !clientStatus);

    dispatch(
      actions.businessClients.updateBusinessClientStatusAction(
        params.id,
        newStatus
      )
    );
  };

  const openDeleteClientModal = () => {
    const payload = {
      modalName: 'confirmModal',
      title: 'Ištrinti kliento duomenis',
      desc: 'Ar tikrai norite ištrinti kliento duomenis?',
      onConfirm: () =>
        dispatch(businessClientsActions.deleteClientData(params.id)),
    };
    dispatch(actions.modals.clearModals());
    dispatch(actions.modals.setModalVisible(payload));
  };

  const handleInputChange = ({ name, value }) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleCheckboxChange = ({ currentTarget: { id, value } }) => {
    const newValue = value === 'true' ? false : true;
    setValues({
      ...values,
      [id]: newValue,
    });
  };

  const handleContractValidFrom = timestamp => {
    setValues({
      ...values,
      contract_start_date: timestamp,
    });
  };

  const handleContractValidTo = timestamp => {
    setValues({
      ...values,
      contract_end_date: timestamp,
    });
  };

  const handleImpersonate = e => {
    e.preventDefault();

    history.push(config.APP_PATHS.home);
    dispatch(actions.auth.impersonateBusinessClient(params.id));
  };

  const handleSearchCompanyDetails = useDebouncedCallback(value => {
    dispatch(actions.businessClients.searchCompanyDetailsAction(value));
  }, 800);

  const handleSearchCompanyDetailsByCode = useDebouncedCallback(value => {
    dispatch(actions.businessClients.searchCompanyDetailsByCodeAction(value));
  }, 800);

  const handleGetSingleCompanyDetails = value => {
    dispatch(actions.businessClients.getSingleCompanyDetailsAction(value));
  };

  const resetSingleCompanyDetails = () => {
    dispatch(actions.businessClients.setSingleCompanyDetailsAction(null));
  };

  const handleCompanyDataPrefill = () => {
    const {
      company_code,
      company_name,
      tax_code,
      address,
      city,
      post_code,
      country_code,
    } = singleCompanyDetails;

    setValues({
      ...values,
      name: company_name,
      company_code,
      tax_code,
      address,
      city,
      postal_code: post_code,
      country_code,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    const error = validationErrors(values);

    if (error) dispatch(actions.error.setErrorAction({ msg: error }));
    else {
      const nameSubmit = values.name;
      const emailSubmit = values.email;
      const ownerNameSubmit = values.owner_full_name;
      const phoneSubmit = values.phone;
      const companyCodeSubmit = values.company_code;
      const taxCodeSubmit = values.tax_code;
      const addressSubmit = values.address;
      const sendInviteSubmit = values.sendPassword;
      const creditLimitSubmit = +values.credit_limit;
      const discountSubmit = values.discount ? +values.discount : 0;
      const contractStartDateSubmit = values.contract_start_date;
      const contractEndDateSubmit = values.contract_end_date;
      const progressiveDiscountSubmit = values.progressive_discount;
      const paymentTermSubmit = +values.payment_term;
      const invoiceEmailsSubmit =
        values.invoice_emails && values.invoice_emails.length > 0
          ? values.invoice_emails.replaceAll(' ', '').split(',')
          : [];

      const body = {
        name: nameSubmit,
        email: emailSubmit,
        invoice_emails: invoiceEmailsSubmit,
        owner_full_name: ownerNameSubmit,
        phone: phoneSubmit,
        company_code: companyCodeSubmit,
        tax_code: taxCodeSubmit,
        address: addressSubmit,
        credit_limit: creditLimitSubmit * 100,
        discount: discountSubmit,
        send_invite: sendInviteSubmit,
        contract_start_date: contractStartDateSubmit,
        contract_end_date: contractEndDateSubmit,
        progressive_discount: progressiveDiscountSubmit,
        payment_term: paymentTermSubmit,
        color: values.color,
        manager_id: values.manager_id || null,
        city: values.city,
        postal_code: values.postal_code,
        country_code: values.country_code,
      };

      dispatch(
        actions.businessClients.updateBusinessClientAction(
          params.id,
          body,
          history
        )
      );
    }
  };

  const handleFileUpload = file => {
    dispatch(actions.businessClients.uploadContractAction(file));
  };

  const handleLogotypeUpload = file => {
    dispatch(actions.businessClients.uploadLogotypeAction(file));
  };

  const generalInfoContent = () => (
    <GeneralInfoTabContent
      values={values}
      sendPassword={sendPassword}
      setSendPassword={setSendPassword}
      contractValidFrom={contractValidFrom}
      setContractValidFrom={setContractValidFrom}
      contractValidTo={contractValidTo}
      setContractValidTo={setContractValidTo}
      handleInputChange={handleInputChange}
      handleCheckboxChange={handleCheckboxChange}
      handleContractValidFrom={handleContractValidFrom}
      handleContractValidTo={handleContractValidTo}
      handleFileUpload={handleFileUpload}
      handleLogotypeUpload={handleLogotypeUpload}
      loading={loading}
      handleImpersonate={handleImpersonate}
      handleSubmit={handleSubmit}
      managerAdmins={managerAdmins}
      countries={countries}
      companyDetails={companyDetails}
      companyDetailsByCode={companyDetailsByCode}
      searchCompanyDetails={handleSearchCompanyDetails}
      searchCompanyDetailsByCode={handleSearchCompanyDetailsByCode}
      companyDetailsLoading={companyDetailsLoading}
      companyDetailsError={companyDetailsError}
      companyDetailsByCodeError={companyDetailsByCodeError}
      getSingleCompanyDetails={handleGetSingleCompanyDetails}
    />
  );

  useEffect(() => {
    if (singleCompanyDetails) {
      handleCompanyDataPrefill();
    }
  }, [singleCompanyDetails]);

  useEffect(() => {
    return () => {
      resetSingleCompanyDetails();
    };
  }, []);

  const openModal = (membership = null) => {
    const payload = {
      modalName: 'defaultModal',
      title: 'Nuolaidų planai',
      children: (
        <EditMembership
          businessClientId={selectedClient.id}
          selectedMembership={membership}
          dispatch={dispatch}
          memberships={memberships}
        />
      ),
    };

    dispatch(actions.modals.setModalVisible(payload));
  };

  const membershipsTable = () => {
    return (
      <MembershipsTabContent
        selectedClientMemberships={selectedClientMemberships}
        loading={loading}
        openModal={openModal}
      />
    );
  };

  const paperContent = () => {
    switch (activeTab) {
      case 0:
        return generalInfoContent();
      case 1:
        return membershipsTable();
      default:
        return null;
    }
  };

  return (
    <section>
      <Header>
        <div className={styles.leftHeaderItemsContainer}>
          <Breadcrumb
            title={config.PAGES.businessClients}
            subtitle={selectedClient.phone}
            baseUrl={config.APP_PATHS.businessClients}
          />
          {/* <Button color="dark" onClick={openDeleteClientModal}>
            Ištrinti duomenis
          </Button> */}
        </div>

        <Toggle
          onChange={toggleStatus}
          checked={clientStatus}
          showMeta={true}
          onText="Aktyvus"
          offText="Deaktyvuotas"
        />
      </Header>

      <PaperContainer>
        <Tabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabsContent={tabsContent}
        >
          {paperContent()}
        </Tabs>

        <div>
          <PaperNote>
            <BusinessClientFinanceInfo businessClient={selectedClient} />
          </PaperNote>

          <PaperNav>
            <ButtonLink
              icon="nav-orders"
              url={config.APP_PATHS.ordersByBusinessClient(selectedClient.id)}
            >
              Užsakymai
            </ButtonLink>
            <ButtonLink
              icon="nav-invoices"
              url={config.APP_PATHS.invoicesByBusinessClient(
                selectedClient.company_code
              )}
            >
              Sąskaitos
            </ButtonLink>
            <ButtonLink
              icon="nav-clients"
              url={config.APP_PATHS.clientsByBusinessClient(selectedClient.id)}
            >
              Darbuotojai
            </ButtonLink>
          </PaperNav>
        </div>
      </PaperContainer>
    </section>
  );
};

const mapStateToProps = state => ({
  businessClients: state.businessClients,
  memberships: state.memberships.memberships,
  dropdowns: state.dropdowns,
});

EditBusinessClient.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  businessClients: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    selectedClient: PropTypes.object.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(EditBusinessClient);
