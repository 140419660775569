export const LIMITS = {
  title: 100,
  notification_title: 55,
  notification_body: 200,
};

export const validationErrors = (values = {}) => {
  let msg;

  const isConditionalAudienceActive = values.conditionalAudience;

  if (values.title.length > LIMITS.title) {
    msg = `Kampanijos pavadinimas negali būti ilgesnis nei ${LIMITS.title} ženklai`;
  }
  if (!values.title) {
    msg = 'Įveskite kampanijos pavadinimą';
  }

  if (values.notification_title_lt.length > LIMITS.notification_title) {
    msg = `Pranešimo pavadinimas (LT) negali būti ilgesnis nei ${LIMITS.notification_title} ženklai`;
  }
  if (values.notification_title_en.length > LIMITS.notification_title) {
    msg = `Pranešimo pavadinimas (EN) negali būti ilgesnis nei ${LIMITS.notification_title} ženklai`;
  }
  if (values.notification_title_ru.length > LIMITS.notification_title) {
    msg = `Pranešimo pavadinimas (RU) negali būti ilgesnis nei ${LIMITS.notification_title} ženklai`;
  }

  if (!values.notification_title_lt) {
    msg = 'Įveskite pranešimo pavadinimą (LT)';
  }
  if (!values.notification_title_en) {
    msg = 'Įveskite pranešimo pavadinimą (EN)';
  }
  if (!values.notification_title_ru) {
    msg = 'Įveskite pranešimo pavadinimą (RU)';
  }

  if (values.notification_body_lt.length > LIMITS.notification_body) {
    msg = `Pranešimo tekstas (LT) negali būti ilgesnis nei ${LIMITS.notification_body} ženklai`;
  }
  if (values.notification_body_en.length > LIMITS.notification_body) {
    msg = `Pranešimo tekstas (EN) negali būti ilgesnis nei ${LIMITS.notification_body} ženklai`;
  }
  if (values.notification_body_ru.length > LIMITS.notification_body) {
    msg = `Pranešimo tekstas (RU) negali būti ilgesnis nei ${LIMITS.notification_body} ženklai`;
  }

  if (!values.notification_body_lt) {
    msg = 'Įveskite pranešimo tekstą (LT)';
  }
  if (!values.notification_body_en) {
    msg = 'Įveskite pranešimo tekstą (EN)';
  }
  if (!values.notification_body_ru) {
    msg = 'Įveskite pranešimo tekstą (RU)';
  }

  if (!values.send_at_date) {
    msg = 'Įveskite pranešimo siuntimo datą';
  }
  if (!values.send_at_time) {
    msg = 'Įveskite pranešimo siuntimo laiką';
  }

  if (isConditionalAudienceActive && !values.condition_from_date) {
    msg = 'Įveskite sąlygos pradžios datą';
  }

  if (isConditionalAudienceActive && !values.condition_from_time) {
    msg = 'Įveskite sąlygos pradžios laiką';
  }

  if (isConditionalAudienceActive && !values.condition_to_date) {
    msg = 'Įveskite sąlygos pabaigos datą';
  }

  if (isConditionalAudienceActive && !values.condition_to_time) {
    msg = 'Įveskite sąlygos pabaigos laiką';
  }

  return msg;
};
